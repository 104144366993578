import React from "react";
import Proptypes from "prop-types";
import { Link } from "react-router-dom";

import Util from "./lib/Util";
import Api from "./lib/Api";
import Page from "./Components/Page";
import ChevRetour from "./Components/ChevRetour";
import Button from "./Components/Button";

class PurchaseSignin extends React.Component {
	constructor(props) {
		super(props);
		props.App.storyNeed("/PURCHASE", Util.HOME);
		this.state = this.props.App.storyGet({ email: "", code: "" });
		this.state.resend = true;
	}
	changeField(value, field) {
		let update = {};
		update[field] = Util.nice(value, field);
		this.setState(update);
		this.props.App.storyUpdate(update);
	}
	reSendCode() {
		this.setState({ resend: false });
		new Api().fetch("sendCode", { email: this.state.email }, result => {
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
		});
	}
	sendCode = () => {
		if (!this.checkButton()) return;
		this.props.App.signin(this.state.email, this.state.code, result => {
			this.props.App.go(Util.PURCHASE_CONFIRM);
		});
	};
	checkButton() {
		if (Util.checkEmail(this.state.email)) return false;
		if (this.state.code.length != 6) return false;
		return true;
	}

	renderButton() {
		const { App } = this.props;

		const button = (
			<Button
				onClick={() => this.sendCode()}
				enabled={this.checkButton()}
				className="step"
			>
				{Util.loc("Suivant")}
			</Button>
		);

		return (
			<div id="buttons-container">
				<div>
					<ChevRetour to={Util.PURCHASE_FORM} />
					{button}
				</div>
			</div>
		);
	}

	render() {
		const { App } = this.props;
		return (
			<Page App={App} withoutHeader>
				<section id="sign-in">
					<div className="row containt px-4 px-md-0">
						<div className="container">
							<div className="row">
								<div className="col-12 col-sm-10 col-md-8 col-lg-6 pt-block mx-auto mt-5 text-center">
									<p className="worksans-light">
										{Util.loc(
											"Je saisis le code à 6 chiffres que je viens de recevoir par email à cette adresse :"
										)}
										<br />
										<br />
										<span>{this.state.email}</span>
									</p>
									<p>
										<small>
											{Util.loc(
												"(à noter : l’email peut mettre plus d’une minute à arriver. Pensez aussi à vérifier dans votre courrier indésirable)"
											)}
										</small>
									</p>
								</div>
							</div>
							<div className="row">
								<div
									className="col-12 col-sm-10 col-md-8 col-lg-6 pt-block m-auto needs-validation"
									noValidate
								>
									<section className="px-1">
										<div className="form-row">
											<div className="w-100">
												<div className="row form-group">
													<div className="col-12 px-0">
														<input
															type="text"
															className="form-control formControlStep mb-3"
															id="code"
															placeholder={Util.loc(
																"Le code à 6 chiffres"
															)}
															value={
																this.state.code
															}
															onKeyPress={event => {
																if (
																	event.key ===
																	"Enter"
																)
																	event.target.blur();
															}}
															onChange={event => {
																this.changeField(
																	event.target
																		.value,
																	"code"
																);
															}}
														/>
														<label htmlFor="code">
															{Util.loc(
																"Le code à 6 chiffres"
															)}
														</label>
													</div>
												</div>
											</div>
										</div>
									</section>
									<div className="row d-flex flex-row justify-content-center mt-4">
										{this.renderButton()}
									</div>
									{this.state.resend ? (
										<p className="text-center">
											<a
												className="forgetPassword"
												onClick={() =>
													this.reSendCode()
												}
											>
												{Util.loc(
													"Renvoyer le code par email"
												)}
											</a>
										</p>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</section>
			</Page>
		);
	}
}

PurchaseSignin.propTypes = {
	App: Proptypes.object.isRequired,
};

export default PurchaseSignin;
