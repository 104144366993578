import React from "react";
import Proptypes from "prop-types";
import { Link } from "react-router-dom";

import Util from "./lib/Util";
import Api from "./lib/Api.js";

import "bootstrap/js/src/dropdown";
import { Container, Draggable } from "react-smooth-dnd";

import CenteredContainer from "./Components/CenteredContainer";
import TopMenu from "./Components/TopMenu";
import Footer from "./Components/Footer";
import ButtonSuivant from "./Components/ButtonSuivant";
import TimePicker from "./Components/TimePicker";

// Components
import Retour from "./Components/Retour";
import ModalBellAlert from "./Components/ModalBellAlert";

// Svg
import Check from "../src/style/img/icon-check.svg";
import Info from "../src/style/img/icon-info.svg";
import SelectCountry from "./Components/SelectCountry";
import { COUNTRIES } from "./lib/Countries";

class BellAlert extends React.Component {
	constructor(props) {
		super(props);
		this.props.App.storyNeedMulti(["/BELL_UPDATE", "/VISIT"], Util.HOME);
		this.story = this.props.App.storyGet();
		this.id = this.story.alertId;
		this.redirect = this.story.url === "/VISIT";

		let alert = false;
		if (this.redirect) {
			let phone = this.props.App.myFirstPhone();
			alert = {
				id: Util.ALERT_FIRST,
				temporary: 0,
				type: phone ? Util.ALERT_PHONE : 0,
				label: this.story.firstname,
				phone: phone ? phone : "",
				email: "",
				from: 0,
				to: 0,
				except: "-------",
				delay: "86400",
				policy: Util.POLICY_ANONYMOUS_ALWAYS,
			};
		} else {
			if (this.story.alerts)
				for (let a of this.story.alerts)
					if (a.id === this.id) alert = a;
			if (!alert)
				alert = {
					id: this.id,
					temporary: 0,
					type: Util.ALERT_PHONE,
					label: "",
					phone: "",
					email: "",
					from: 8 * 60 + 30,
					to: 21 * 60 + 30,
					except: "-------",
					delay: 0,
					policy: Util.POLICY_ANONYMOUS_ALWAYS,
				};
		}
		this.state = {
			label: alert.label,
			temporary: alert.temporary,
			countryCode: Util.countryFromPhone(alert.phone),
			phone: alert.phone,
			email: alert.email,
			except: alert.except,
			from: alert.from,
			to: alert.to,
			delay: alert.delay,
			policy: alert.policy,
			withPhone: alert.type & Util.ALERT_PHONE,
			withEmail: alert.type & Util.ALERT_EMAIL,
			withSms: alert.type & Util.ALERT_SMS,
			withAnonym: alert.policy !== Util.POLICY_ANONYMOUS_NEVER,
			auto_label: [],
			auto_phone: [],
			auto_email: [],
			auto_sms: [],
			schedule: false,
			check: 0,
			display: false,
		};

		this.items = [
			{
				num: 1,
				optionText: Util.loc("Option 1 :"),
				mainText: Util.loc("rester anonyme :"),
				subText: Util.loc(
					"vous recevrez uniquement cette alerte par email."
				),
				policy: Util.POLICY_ANONYMOUS_ALWAYS,
			},
			{
				num: 2,
				optionText: Util.loc("Option 2 :"),
				mainText: Util.loc("lever l’anonymat :"),
				subText: Util.loc(
					"votre numéro de téléphone ne sera plus masqué pour cette alerte."
				),
				policy: Util.POLICY_ANONYMOUS_BEST,
			},
		];

		this.days = [
			{ val: 1, name: Util.loc("lundi") },
			{ val: 2, name: Util.loc("mardi") },
			{ val: 3, name: Util.loc("mercredi") },
			{ val: 4, name: Util.loc("jeudi") },
			{ val: 5, name: Util.loc("vendredi") },
			{ val: 6, name: Util.loc("samedi") },
			{ val: 0, name: Util.loc("dimanche") },
		];
		this.delays = {};
		this.delays["" + 3600 * 1] = Util.loc("1 heure");
		this.delays["" + 3600 * 4] = Util.loc("4 heures");
		this.delays["" + 86400] = Util.loc("1 jour");
		this.delays["" + 86400 * 2] = Util.loc("2 jours");
		this.delays["" + 86400 * 3] = Util.loc("3 jours");

		this.contacts = this.props.App.getContacts(true, true);

		this.urlBack = props.claim_alert
			? Util.CLAIM_ALERTS
			: Util.BELL_EDIT + "/" + this.story.code;
	}

	getContact(label) {
		for (let c of this.contacts) if (c.label === label) return c;
		return { label: "", phones: [], emails: [] };
	}

	onDelete() {
		if (this.id < 0) return this.props.App.go(this.urlBack);
		this.props.App.confirmPopup(
			Util.loc("Etes-vous sûr de vouloir supprimer cette alerte ?"),
			result => {
				if (!result) return;
				this.props.App.alertUpdateField(this.id, null);
				this.props.App.storyProcess();
				this.props.App.go(this.urlBack);
			}
		);
	}

	onChangeDay(value, index) {
		this.except[index] = value;
		let except = Util.exceptEncode(this.except);
		this.setState({ except: except }, () => {
			this.applyChanges();
		});
	}

	onChangeTime(value, field) {
		//		console.log("onChangeTime", value);
		this.onChange(Util.timeToMinutes(value), field, false, true);
	}

	onChangeDelay(value) {
		this.setState({ delay: value });
	}

	onEnter(field) {
		console.log("onEnter " + field);
		this["enter_" + field] = true;
	}

	onLeave(field) {
		console.log("onLeave " + field);
		this["enter_" + field] = false;
		this.setState({ ["auto_" + field]: [] });
	}

	filterCountry(lst) {
		let result = [];
		for (let v of lst)
			if (Util.countryFromPhone(v) == this.state.countryCode)
				result.push(v);
		return result;
	}
	computeAutoLabel(value, field) {
		value = value.toLowerCase();
		console.log("computeAutoLabel", field, value);
		let auto = [];
		if (field === "label")
			for (let c of this.contacts) {
				let db = c.label.toLowerCase();
				if (value !== db && db.indexOf(value) >= 0) auto.push(c);
			}
		else {
			let c = this.getContact(this.state.label);
			let lst =
				field === "phone" || field === "sms"
					? this.filterCountry(c.phones)
					: c.emails;
			for (let v of lst) {
				console.log("v :", v);
				let db = v.toLowerCase();
				if (value !== db && db.indexOf(value) >= 0) {
					if (field !== "sms" || !Util.checkSmsNumber(v))
						auto.push(v);
				}
			}
		}
		console.log("->", auto);
		this.setState({ ["auto_" + field]: auto });
	}

	selectAuto(c, field) {
		//		console.log("selectAuto", c, field);
		if (field === "label") {
			// TODO : ===1 or >=1 ?
			if (c.phones.length) {
				let phone = c.phones[0];
				this.state.countryCode = Util.countryFromPhone(phone);
				this.state.phone = Util.niceInternationalPhone(
					phone,
					this.state.countryCode
				);
				this.state.withPhone = true;
				this.state.withSms &= Util.checkSmsNumber(
					this.state.phone,
					this.state.countryCode
				)
					? false
					: true;
			} else {
				this.state.withPhone = false;
				this.state.withSms = false;
				this.state.phone = "";
			}
			if (c.emails.length) {
				this.state.withEmail = true;
				this.state.email = c.emails[0];
			} else {
				this.state.withEmail = false;
				this.state.email = "";
			}
			this.onChange(c.label, field);
		} else if (field === "phone") {
			this.state.countryCode = Util.countryFromPhone(c);
			this.onChange(c, field);
		} else if (field === "sms") {
			this.state.countryCode = Util.countryFromPhone(c);
			this.onChange(c, "phone");
		} else this.onChange(c, field);
		// calls to onChange will not fire the applyChanges
		this["enter_" + field] = false;
		this.setState({ ["auto_" + field]: [] }, () => this.applyChanges());
	}

	onChange(value, field, auto, process) {
		let update = {};
		if (field === "phone") {
			value = Util.niceInternationalPhone(value, this.state.countryCode);
			update.phone = value;
			update.withSms = Util.checkSmsNumber(value, this.state.countryCode)
				? false
				: true;
		} else if (field === "sms") {
			value = Util.niceInternationalPhone(value, this.state.countryCode);
			update.phone = value;
		} else {
			value = Util.nice(value, field);
			update[field] = value;
		}

		this.setState(update, () => {
			if (process) this.applyChanges(); // only for schedule
		});
		if (auto) setTimeout(() => this.computeAutoLabel(value, field), 100);
	}
	onBlur(value, field) {
		console.log("onBlur", value, field);
		if (field === "label") {
			value = value.trim();
			let update = { label: value };
			this.setState(update);
		}
		let enter = "enter_" + field;
		console.log(enter, this[enter]);
		if (this[enter]) return;

		this.setState({ ["auto_" + field]: [] }, () => this.applyChanges());
	}

	toggle(field, value) {
		let countryCode = this.state.countryCode;

		//		console.log("toggle",field,this.state.phone);
		let update = { [field]: value };
		if (field === "withPhone" && this.state.phone == "")
			return this.setState(update);
		if (field === "withSms" && this.state.phone == "")
			return this.setState(update);
		if (field === "withEmail" && this.state.email == "")
			return this.setState(update);
		if (value) {
			if (
				field === "withSms" &&
				this.state.phone !== "" &&
				Util.checkSmsNumber(this.state.phone, countryCode)
			)
				return;
			if (field == "withAnonym")
				this.setState({ policy: Util.POLICY_ANONYMOUS_ALWAYS });
		} else {
			if (field === "withPhone" && !this.state.withSms) {
				update.phone = "";
			}
			if (field == "withAnonym")
				this.setState({ policy: Util.POLICY_ANONYMOUS_NEVER });
		} //		console.log(JSON.stringify(update));
		this.setState(update, () => this.applyChanges());
	}

	applyChanges(force) {
		console.log("...applyChanges to story and process");
		if (this.redirect && !force) return;
		if (this.id == Util.ALERT_LAST && !force) return;
		if (this.props.App.checkBellAlert(this.state)) return;
		let type = 0;
		if (this.state.withPhone) type |= Util.ALERT_PHONE;
		if (this.state.withEmail) type |= Util.ALERT_EMAIL;
		if (this.state.withSms) type |= Util.ALERT_SMS;

		let alert = {
			type: type,
			label: this.state.label,
			phone: this.state.phone,
			email: this.state.email,
			except: this.state.except,
			from: this.state.from,
			to: this.state.to,
			policy: this.state.policy,
		};
		//		console.log("newalert",JSON.stringify(alert));

		if (this.redirect) {
			this.props.App.storyUpdate({
				url: "/BELL_USER",
				alerts: [alert],
				delay: this.state.delay,
			});
			this.props.App.storyProcess();
		} else if (this.id == Util.ALERT_LAST) {
			//			console.log("add last");
			alert.id = this.id;
			this.id = 0;
			this.props.App.alertNew(alert);
			this.props.App.storyProcess(result => {
				this.id = result.created;
				this.props.App.storyUpdate({ alertId: this.id });
				this.props.App.go(this.urlBack);
			});
		} else {
			this.props.App.alertUpdateField(this.id, alert);
			this.props.App.storyProcess();
		}
	}

	onConfirm() {
		this.applyChanges(true);
	}

	onCountry(value) {
		console.log("code pays :", value);
		let countryCode = this.state.countryCode;

		if (value === countryCode) {
			console.log("[country code] no change");
			return;
		}
		this.state.phone = "";
		this.state.countryCode = value;
		this.setState({
			countryCode: value,
		});
		if (this.refInput) this.refInput.focus();
	}
	back() {
		if (!this.props.App.checkBellAlert(this.state))
			return this.props.App.go(this.urlBack);
		this.props.App.confirmPopup(
			Util.loc(
				"Il reste des erreurs sur cette page\n\nVoulez-vous vraiment la quitter ?\n\nEn cliquant 'oui' vos modifications seront perdues."
			),
			res => {
				if (res) this.props.App.go(this.urlBack);
			},
			false
		);
	}
	render() {
		const { App, className } = this.props;
		this.contacts = this.props.App.getContacts(true, true);

		this.except = Util.exceptParse(this.state.except);
		let except = this.except;

		const [hourFrom, minutesFrom] = Util.timeFromMinutes(
			this.state.from
		).split(":");
		const [hourTo, minutesTo] = Util.timeFromMinutes(this.state.to).split(
			":"
		);
		let id = Util.random();

		let checkPhone = Util.checkInternationalPhone(
			this.state.phone,
			this.state.countryCode,
			this.props.withNext
		);
		if (
			!checkPhone &&
			this.state.withSms &&
			Util.checkSmsNumber(this.state.phone, this.state.countryCode)
		)
			checkPhone = { display: "block" };
		return (
			<CenteredContainer id="page" className={className ?? ""}>
				<div id="bell-alert" className="claimBackground">
					<div
						className={
							this.state.display === false
								? "modal-none"
								: "modal-block"
						}
					>
						<ModalBellAlert
							onClick={() => {
								this.setState({
									display: false,
								});
							}}
						/>
					</div>
					<TopMenu App={App} />
					<div className="padd20">
						{this.redirect ? (
							<>
								<div className="row form-group d-flex flex-row justify-content-center mt-3">
									<div className="col-12 col-md-10 col-lg-6">
										<div className="content-info">
											<p className="etape text-left">
												{Util.loc("REDIRECTION")}
											</p>
											<div
												onClick={() => {
													this.setState({
														display: true,
													});
												}}
											>
												<img
													className="img-info"
													src={Info}
												/>
											</div>
										</div>
										<p className="color-title">
											{Util.loc(
												"Je veux rediriger provisoirement les alertes pendant :"
											)}
										</p>
									</div>
								</div>
								<div className="row form-group d-flex flex-row justify-content-center">
									<div className="col-12 col-md-10 col-lg-6">
										<div className="redirect-moment-content">
											{Object.keys(this.delays).map(
												(key, i, arr) => {
													if (i === 0) {
														return (
															<div
																className={`redirect-element redirect-first ${
																	key ===
																	this.state
																		.check
																		? "redirect-check"
																		: ""
																}`}
																key={i}
																onClick={() => {
																	let update = {
																		delay: key,
																	};
																	this.setState(
																		update
																	);
																	this.setState(
																		{
																			check: key,
																		}
																	);
																}}
															>
																{
																	this.delays[
																		key
																	]
																}

																{this.state
																	.check ===
																key ? (
																	<img
																		src={
																			Check
																		}
																	/>
																) : null}
															</div>
														);
													} else if (
														i ===
														arr.length - 1
													) {
														return (
															<div
																className={`redirect-element redirect-last ${
																	key ===
																	this.state
																		.check
																		? "redirect-check"
																		: ""
																}`}
																key={i}
																onClick={() => {
																	let update = {
																		delay: key,
																	};
																	this.setState(
																		update
																	);
																	this.setState(
																		{
																			check: key,
																		}
																	);
																}}
															>
																{
																	this.delays[
																		key
																	]
																}

																{this.state
																	.check ===
																key ? (
																	<img
																		src={
																			Check
																		}
																	/>
																) : null}
															</div>
														);
													} else {
														return (
															<div
																className={`redirect-element ${
																	key ===
																	this.state
																		.check
																		? "redirect-check"
																		: ""
																}`}
																key={i}
																onClick={() => {
																	let update = {
																		delay: key,
																	};
																	this.setState(
																		update
																	);
																	this.setState(
																		{
																			check: key,
																		}
																	);
																}}
															>
																{
																	this.delays[
																		key
																	]
																}

																{this.state
																	.check ===
																key ? (
																	<img
																		src={
																			Check
																		}
																	/>
																) : null}
															</div>
														);
													}
												}
											)}
										</div>
										{/*  */}
									</div>
								</div>
								<div className="row form-group d-flex flex-row justify-content-center mt-5">
									<div className="col-12 col-md-10 col-lg-6">
										<p className="etape text-left">
											{Util.loc("COORDONNÉES")}
										</p>
										<p className="color-title">
											{Util.loc(
												"Je vérifie mes coordonnées de contact et si besoin je les modifie."
											)}
										</p>
									</div>
								</div>
							</>
						) : (
							<div className="row d-flex flex-row justify-content-center mb-4">
								<div className="col-12 col-md-10 col-lg-6">
									<div className="row btnRevenirSuivant withNext">
										{/* <ChevRetour to={Util.CLAIM_USAGE} /> */}
										<Retour
											to={() => this.back()}
											title={Util.loc("Alertes")}
										/>
									</div>
								</div>
							</div>
						)}
						<div className="row form-group d-flex flex-row justify-content-center mt-4">
							<div className="col-12 col-md-10 col-lg-6">
								<input
									type="text"
									autoComplete={Util.random()}
									spellCheck={false}
									className="form-control"
									id={"label" + id}
									placeholder={""}
									required
									value={this.state.label}
									disabled={this.redirect}
									onKeyPress={event => {
										if (event.key === "Enter")
											event.target.blur();
									}}
									onFocus={event => {
										this.computeAutoLabel(
											this.state.label,
											"label"
										);
									}}
									onBlur={event => {
										this.onBlur(this.state.label, "label");
									}}
									onChange={event => {
										this.onChange(
											event.target.value,
											"label",
											true
										);
									}}
								/>
								<label htmlFor={"label" + id}>
									{this.redirect ? "" : Util.loc("Prénom *")}
								</label>
								<div
									style={Util.check(
										this.state.label,
										this.props.withNext
									)}
									className="invalid-tooltip"
								>
									{Util.loc("Veuillez remplir ce champ")}
								</div>
								{this.state.auto_label.length ? (
									<div
										className="custom-select"
										style={{ borderStyle: "none" }}
									>
										<div
											className="dropdown-menu select show"
											onMouseEnter={() =>
												this.onEnter("label")
											}
											onMouseLeave={() =>
												this.onLeave("label")
											}
										>
											{this.state.auto_label.map(
												(a, i) => {
													return (
														<a
															key={i}
															className="dropdown-item"
															onClick={() => {
																this.selectAuto(
																	a,
																	"label"
																);
															}}
														>
															{a.label}
														</a>
													);
												}
											)}
										</div>
									</div>
								) : null}
							</div>
						</div>
						{this.state.temporary ? (
							<div className="row form-group">
								<div className="col-12 validate-info">
									{Util.fillStr(
										Util.loc("Valide jusqu'au [date]"),
										{
											date: Util.timeFormat(
												this.state.temporary
											),
										}
									)}
								</div>
							</div>
						) : null}
						<div className="row form-group d-flex flex-row justify-content-center mt-3">
							<div className="col-12 col-md-10 col-lg-6">
								<span
									className={
										this.state.withPhone
											? "alert-title"
											: "alert-title-off"
									}
								>
									{Util.loc("Par téléphone")}
								</span>

								<div
									className="custom-control custom-switch"
									style={{ float: "right" }}
								>
									<input
										type="checkbox"
										className="custom-control-input"
										id="togglePhone"
										checked={this.state.withPhone}
										onChange={event =>
											this.toggle(
												"withPhone",
												event.target.checked
											)
										}
									/>
									<label
										className="custom-control-label text-left"
										htmlFor={"togglePhone"}
									>
										&nbsp;
									</label>
								</div>
							</div>
						</div>
						{this.state.withPhone ? (
							<div className="row form-group d-flex flex-row justify-content-center">
								<div className="col-12 col-md-10 col-lg-6">
									<div>
										<SelectCountry
											margin={"10px 5px"}
											onCountry={country =>
												this.onCountry(country)
											}
											countryCode={this.state.countryCode}
										/>
										<input
											type="tel"
											autoComplete={Util.random()}
											spellCheck={false}
											className="form-control"
											id={"phone" + id}
											ref={ref => (this.refInput = ref)}
											placeholder={Util.loc(
												"Numéro de téléphone"
											)}
											required
											value={this.state.phone}
											onKeyPress={event => {
												if (event.key === "Enter")
													event.target.blur();
											}}
											onFocus={event => {
												this.computeAutoLabel(
													this.state.phone,
													"phone"
												);
											}}
											onBlur={event => {
												this.onBlur(
													this.state.phone,
													"phone"
												);
											}}
											onChange={event => {
												this.onChange(
													event.target.value,
													"phone",
													true
												);
											}}
										/>
										<div
											style={checkPhone}
											className="invalid-tooltip"
										>
											{Util.loc(
												"Veuillez saisir un numéro valide"
											)}
										</div>
										{this.state.auto_phone.length ? (
											<div
												className="custom-select"
												style={{ borderStyle: "none" }}
											>
												<div
													className="dropdown-menu select phone show"
													style={{
														top: "24px !important",
													}}
													onMouseEnter={() =>
														this.onEnter("phone")
													}
													onMouseLeave={() =>
														this.onLeave("phone")
													}
												>
													{this.state.auto_phone.map(
														(a, i) => {
															return (
																<a
																	key={i}
																	className="dropdown-item"
																	onClick={() =>
																		this.selectAuto(
																			a,
																			"phone"
																		)
																	}
																>
																	{a}
																</a>
															);
														}
													)}
												</div>
											</div>
										) : null}
									</div>
								</div>
							</div>
						) : null}
						{this.state.withPhone &&
						!this.redirect &&
						!this.state.temporary &&
						!checkPhone ? (
							<div className="row form-group d-flex flex-row justify-content-center">
								<div className="col-12 col-md-10 col-lg-6">
									{this.state.schedule ? (
										<div className="content-hour">
											<div className="mt-5 mb-3">
												<div
													id="timepicker-content"
													className="table-timepicker m-auto d-flex"
												>
													<div className="timepicker-group">
														<TimePicker
															onTimeChange={(
																hour,
																minutes
															) =>
																this.onChangeTime(
																	`${hour}:${minutes}`,
																	"from"
																)
															}
															initialHour={parseInt(
																hourFrom
															)}
															initialMinutes={parseInt(
																minutesFrom
															)}
															prefix={Util.loc(
																"De"
															)}
														/>
													</div>
													<div className="timepicker-group">
														<TimePicker
															onTimeChange={(
																hour,
																minutes
															) =>
																this.onChangeTime(
																	`${hour}:${minutes}`,
																	"to"
																)
															}
															initialHour={parseInt(
																hourTo
															)}
															initialMinutes={parseInt(
																minutesTo
															)}
															prefix={Util.loc(
																"À"
															)}
														/>
													</div>
												</div>
											</div>
											<div className="form-group">
												<div className="responsive-toggle text-left m-auto px-0">
													{this.days.map(
														(elem, index) => {
															let ref =
																"mail-except-" +
																index +
																"-" +
																id;
															return (
																<div
																	key={index}
																>
																	<div className="custom-control custom-switch width-week">
																		<input
																			type="checkbox"
																			className="custom-control-input"
																			id={
																				ref
																			}
																			checked={
																				!except[
																					elem
																						.val
																				]
																			}
																			onChange={event => {
																				this.onChangeDay(
																					!event
																						.target
																						.checked,
																					elem.val
																				);
																			}}
																		/>
																		<label
																			className="custom-control-label text-left"
																			/* style={{
															cursor: "pointer",
														}} */
																			style={
																				except[
																					elem
																						.val
																				] ===
																				false
																					? {
																							cursor:
																								"pointer",
																							color:
																								"#0d4c5b",
																					  }
																					: {
																							cursor:
																								"pointer",
																							color:
																								"#0d4b5b5e",
																					  }
																			}
																			htmlFor={
																				ref
																			}
																		>
																			{
																				elem.name
																			}
																		</label>
																	</div>
																</div>
															);
														}
													)}
												</div>
											</div>
										</div>
									) : (
										<>
											<strong>
												{Util.loc("Plage horaire :") +
													" "}
											</strong>
											<span
												className="alert-week"
												style={{
													cursor: "pointer",
													textDecoration: "underline",
												}}
												onClick={() => {
													this.setState({
														schedule: true,
													});
												}}
											>
												{Util.schedule(this.state)}
											</span>
											<br />
											{Util.loc(
												"Cliquez sur le lien pour modifier les horaires."
											)}
										</>
									)}
								</div>
							</div>
						) : null}

						<div className="row form-group d-flex flex-row justify-content-center mt-3">
							<div className="col-12 col-md-10 col-lg-6">
								<span
									className={
										this.state.withEmail
											? "alert-title"
											: "alert-title-off"
									}
								>
									{Util.loc("Par email")}
								</span>
								<div
									className="custom-control custom-switch"
									style={{ float: "right" }}
								>
									<input
										type="checkbox"
										className="custom-control-input"
										id="toggleEmail"
										checked={this.state.withEmail}
										onChange={event =>
											this.toggle(
												"withEmail",
												event.target.checked
											)
										}
									/>
									<label
										className="custom-control-label text-left"
										htmlFor={"toggleEmail"}
									>
										&nbsp;
									</label>
								</div>
							</div>
						</div>
						<div className="row form-group d-flex flex-row justify-content-center">
							<div className="col-12 col-md-10 col-lg-6">
								{this.state.withEmail ? (
									<div>
										<input
											type="text"
											autoComplete={Util.random()}
											spellCheck={false}
											className="form-control"
											id={"email" + id}
											placeholder={Util.loc("email")}
											required
											value={this.state.email}
											onKeyPress={event => {
												if (event.key === "Enter")
													event.target.blur();
											}}
											onFocus={event => {
												this.computeAutoLabel(
													this.state.email,
													"email"
												);
											}}
											onBlur={event => {
												this.onBlur(
													this.state.email,
													"email"
												);
											}}
											onChange={event => {
												this.onChange(
													event.target.value,
													"email",
													true
												);
											}}
										/>

										<div
											style={Util.checkEmail(
												this.state.email,
												this.props.withNext
											)}
											className="invalid-tooltip"
										>
											{Util.loc(
												"Veuillez remplir ce champ correctement"
											)}
										</div>
										{this.state.auto_email.length ? (
											<div
												className="custom-select"
												style={{ borderStyle: "none" }}
											>
												<div
													className="dropdown-menu select show"
													onMouseEnter={() =>
														this.onEnter("email")
													}
													onMouseLeave={() =>
														this.onLeave("email")
													}
												>
													{this.state.auto_email.map(
														(a, i) => {
															return (
																<a
																	key={i}
																	className="dropdown-item"
																	onClick={() =>
																		this.selectAuto(
																			a,
																			"email"
																		)
																	}
																>
																	{a}
																</a>
															);
														}
													)}
												</div>
											</div>
										) : null}
									</div>
								) : null}
							</div>
						</div>

						<div className="row form-group d-flex flex-row justify-content-center mt-3">
							<div className="col-12 col-md-10 col-lg-6">
								<span
									className={
										this.state.withSms
											? "alert-title"
											: "alert-title-off"
									}
								>
									{Util.loc("Par SMS")}
								</span>
								<div
									className="custom-control custom-switch"
									style={{ float: "right" }}
								>
									<input
										type="checkbox"
										className="custom-control-input"
										id="toggleSms"
										checked={this.state.withSms}
										onChange={event =>
											this.toggle(
												"withSms",
												event.target.checked
											)
										}
									/>
									<label
										className="custom-control-label text-left"
										htmlFor={"toggleSms"}
									>
										&nbsp;
									</label>
								</div>
							</div>
						</div>
						{this.state.withSms && !this.state.withPhone ? (
							<div className="row form-group d-flex flex-row justify-content-center">
								<div className="col-12 col-md-10 col-lg-6 d-flex mb-3 justif-content-between">
									<div>
										<SelectCountry
											margin={"10px 5px"}
											onCountry={country =>
												this.onCountry(country)
											}
											countryCode={this.state.countryCode}
										/>

										<input
											type="tel"
											autoComplete={Util.random()}
											spellCheck={false}
											className="form-control"
											id={"sms" + id}
											placeholder={Util.loc(
												"Numéro de téléphone"
											)}
											ref={ref => (this.refInput = ref)}
											required
											value={this.state.phone}
											onKeyPress={event => {
												if (event.key === "Enter")
													event.target.blur();
											}}
											onFocus={event => {
												this.computeAutoLabel(
													this.state.phone,
													"sms"
												);
											}}
											onBlur={event => {
												this.onBlur(
													this.state.phone,
													"sms"
												);
											}}
											onChange={event => {
												this.onChange(
													event.target.value,
													"sms",
													true
												);
											}}
										/>

										<div
											style={checkPhone}
											className="invalid-tooltip"
										>
											{Util.loc(
												"Veuillez saisir un numéro valide"
											)}
										</div>
										{this.state.auto_sms.length ? (
											<div
												className="custom-select"
												style={{ borderStyle: "none" }}
											>
												<div
													className="dropdown-menu select show phone"
													onMouseEnter={() =>
														this.onEnter("sms")
													}
													onMouseLeave={() =>
														this.onLeave("sms")
													}
												>
													{this.state.auto_sms.map(
														(a, i) => {
															return (
																<a
																	key={i}
																	className="dropdown-item"
																	onClick={() =>
																		this.selectAuto(
																			a,
																			"sms"
																		)
																	}
																>
																	{a}
																</a>
															);
														}
													)}
												</div>
											</div>
										) : null}
									</div>
								</div>
							</div>
						) : null}
						<div className="row form-group d-flex flex-row justify-content-center">
							<div className="col-12 col-md-10 col-lg-6 d-flex justif-content-between">
								<p
									className={
										this.state.withSms
											? "alert-politique"
											: "alert-politique alert-description-off "
									}
								>
									<strong>
										{Util.loc("Politique d'envoi des sms")}
									</strong>

									<br />
									{Util.loc(
										"Un SMS sera envoyé chaque fois que l’utilisateur qui scanne votre QR code décide d’envoyer un message. Tous les contacts pour lesquels le canal SMS est activé recevront simultanément un SMS."
									)}
									<br />
									<strong>{Util.loc("A noter")} :</strong>
									{Util.loc(
										"Chaque SMS consomme une unité de compte."
									)}
								</p>
							</div>
						</div>

						<div className="row form-group d-flex flex-row justify-content-center mt-3">
							<div className="col-12 col-md-10 col-lg-6">
								<span
									className={
										this.state.withAnonym
											? "alert-title"
											: "alert-title-off"
									}
								>
									{Util.loc("Anonymisation")}
								</span>
								<div
									className="custom-control custom-switch"
									style={{ float: "right" }}
								>
									<input
										type="checkbox"
										className="custom-control-input"
										id="toggleAnonym"
										checked={this.state.withAnonym}
										onChange={event =>
											this.toggle(
												"withAnonym",
												event.target.checked
											)
										}
									/>
									<label
										className="custom-control-label text-left"
										htmlFor={"toggleAnonym"}
									>
										&nbsp;
									</label>
								</div>
							</div>
						</div>
						<div className="row form-group d-flex flex-row justify-content-center">
							<div className="col-12 col-md-10 col-lg-6">
								{this.state.withAnonym ? (
									<>
										<p className="alert-politique">
											{Util.loc(
												"Votre numéro de téléphone sera masqué."
											)}
										</p>
										<p className="alert-politique">
											{Util.fillStr(
												Util.loc(
													"Vous disposez actuellement de [count] unité(s) pour anonymiser vos alertes, sachant qu'un SMS ou une minute d'appel conssoment une unité. À noter : seules les unités disponibles sont consommées !"
												),
												{ count: this.story.count }
											)}
										</p>
										<p className="alert-politique">
											{Util.loc(
												"Lorsque votre solde d'unités sera épuisé, vous pourrez recharger votre compte"
											) + " "}
											<Link
												to={Util.PURCHASE_UNITS}
												style={{
													color: "#26c5f0",
													cursor: "pointer",
												}}
											>
												{Util.loc("ici")}
											</Link>
										</p>
										<p className="alert-politique">
											{Util.loc(
												"Si vous ne souhaitez pas en racheter, vous avez le choix entre deux options :"
											)}
										</p>
										{this.items.map((item, index) => (
											<div
												className="consum-radio-groupe ml-5"
												key={index}
											>
												<label
													className="consum-label-radio"
													htmlFor={
														"option-" + item.num
													}
												>
													{item.optionText + " "}
													<strong>
														{item.mainText + " "}
													</strong>
													{item.subText}
												</label>
												<input
													type="radio"
													id={"option-" + item.num}
													name={"option-" + item.num}
													checked={
														this.state.policy ===
														item.policy
													}
													onChange={() =>
														this.setState(
															{
																policy:
																	item.policy,
															},
															() => {
																this.applyChanges();
															}
														)
													}
												/>
											</div>
										))}
									</>
								) : (
									<>
										<p className="alert-politique">
											{Util.fillStr(
												Util.loc(
													"L'anonymisation des appels et des SMS permet de masquer votre numéro de téléphone. Pour utiliser ce service, vous devez disposer d'unités disponibles. Votre solde actuel est de [count] unité(s)."
												),
												{ count: this.story.count }
											)}
										</p>
										<p className="alert-politique">
											{Util.loc(
												"Pour recharger votre compte, c'est"
											) + " "}
											<Link
												to={Util.PURCHASE_UNITS}
												style={{
													color: "#26c5f0",
													cursor: "pointer",
												}}
											>
												{Util.loc("ici")}
											</Link>
										</p>
									</>
								)}
							</div>
						</div>

						{this.redirect || this.id == Util.ALERT_LAST ? (
							<div className="row">
								<div className="col-8 col-md-6 col-lg-3 m-auto d-flex justify-content-center">
									<button
										onClick={() => this.onConfirm()}
										disabled={App.checkBellAlert(
											this.state
										)}
										className="btn btn-primary btn-block"
									>
										{Util.loc("Je valide")}
									</button>
								</div>
							</div>
						) : (
							<>
								<div className="row">
									<div className="col-8 col-md-6 col-lg-3 m-auto d-flex justify-content-center">
										<button
											onClick={() =>
												this.props.App.go(this.urlBack)
											}
											className="btn btn-primary btn-block"
											disabled={App.checkBellAlert(
												this.state
											)}
										>
											{Util.loc("Valider")}
										</button>
									</div>
								</div>
								<div className="row">
									<div className="col-8 col-md-6 col-lg-3 m-auto d-flex justify-content-center">
										<button
											onClick={() => this.onDelete()}
											className="btn btn-border trash-border"
										>
											{Util.loc("Supprimer")}
										</button>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
				<Footer />
			</CenteredContainer>
		);
	}
}

BellAlert.propTypes = {
	App: Proptypes.object.isRequired,
};

export default BellAlert;
