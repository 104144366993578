import React from "react";
import Proptypes from "prop-types";
import { Link } from "react-router-dom";

import Util from "../lib/Util";

import ChevRevenirIcon from "../style/img/chevron-revenir.svg";

const ChevRetour = ({ id, classNames, to, title }) => (
	<Link id="step-revenir" className="bouttonRevenir" to={to}>
		<img src={ChevRevenirIcon} alt="" />
		{title ? title : Util.loc("Précédent")}
	</Link>
);

ChevRetour.propTypes = {
	classNames: Proptypes.string,
	to: Proptypes.string.isRequired,
};

export default ChevRetour;
