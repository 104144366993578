import React from "react";
import { Link } from "react-router-dom";
import Proptypes from "prop-types";

import Util from "./lib/Util";

import Page from "./Components/Page";
// img
import Enveloppe from "./style/img/enveloppe.svg";

class VisitDone extends React.Component {
	constructor(props) {
		super(props);
		this.props.App.storyNeed("/VISIT", Util.HOME);
		this.isMobile = Util.isMobile();
		this.url = Util.BELL + "/" + this.props.App.storyGet().code;
	}
	next() {
		this.props.App.storyUpdate({
			msg: "",
			callback: "",
			sms: false,
		});
		if (!this.props.App.storyGet().auto) return this.props.App.go("/VISIT");
		this.props.App.storyUpdate({ url: "/VISIT_AUTO" });
		this.props.App.go("/VISIT_AUTO");
	}
	smsUrl() {
		let story = this.props.App.storyGet();
		let str =
			"sms:" + story.sms + ";?&body=" + encodeURIComponent(story.body);
		console.log(str);
		return str;
	}

	render() {
		const { App } = this.props;
		let story = this.props.App.storyGet();

		return (
			<Page App={App} withoutHeader>
				<div
					id="visitDone"
					className="row containt text-center claimBackground"
				>
					<div className="m-auto col-12 col-sm-10 col-md-8 col-lg-6">
						<div className="row">
							<div className="m-auto col-12 pt-block mb-4">
								<div>
									<img
										src={Enveloppe}
										style={{ height: "50px" }}
									/>
								</div>
								<div className="px-5 py-5">
									<p className="text-center font-weight-regular mb-4 mt-4 color-title">
										{Util.loc(
											"Nous venons de prévenir votre correspondant."
										)}
									</p>
								</div>
							</div>
						</div>
						{this.isMobile && story.sms && (
							<div className="row">
								<div className="col-10 m-auto d-flex justify-content-center">
									<a href={this.smsUrl()}>
										<button
											className="btn btn-board btn-block"
											onClick={() => {
												story.sms = false;
											}}
										>
											{Util.loc("Envoyer aussi par sms")}
										</button>
									</a>
								</div>
							</div>
						)}
						<div className="row">
							<div className="col-10 m-auto d-flex justify-content-center">
								<Link to={this.url}>
									<button
										className="btn btn-board btn-block mb-2"
										// onClick={() => this.next()}
									>
										{Util.loc("Accueil")}
									</button>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</Page>
		);
	}
}

VisitDone.propTypes = {
	App: Proptypes.object.isRequired,
};

export default VisitDone;
