import React, { Component } from "react";
import PropTypes from "prop-types";

import Util from "../lib/Util";
import GoogleAnalytics from "../lib/GoogleAnalytics";

import { ReactComponent as CloseButton } from "../style/img/close.svg";
import CookiePresentation from "./CookiePresentation";

class CookiesModal extends Component {
	constructor(props) {
		super(props);

		this.googleAnalytics = GoogleAnalytics.getInstance();

		this.state = {
			googleAnalyticsState: this.googleAnalytics.enabled,
		};
	}

	onToggleGoogleAnalytics = state => {
		// state === true means that we have to start GoogleAnalytics tracking
		if (state) {
			window.localStorage.setItem("google-analytics", "enabled");
			this.googleAnalytics.startWatcher();
		} else {
			window.localStorage.setItem("google-analytics", "disabled");
			this.googleAnalytics.stopWatcher();
		}

		this.setState({ googleAnalyticsState: this.googleAnalytics.enabled });
	};

	render() {
		return (
			<div id="modal-container">
				<div id="cookies-modal">
					<CloseButton onClick={this.props.onClose} />
					<div className="cookies-modal-content">
						<h1>
							{Util.loc(
								"Votre accord sur l'utilisation des cookies."
							)}
						</h1>
						<p>
							{Util.loc(
								"Vous êtes libre d'activer et de désactiver à tout moment les familles de cookies suivantes:"
							)}
						</p>
						<div className="cookie-container">
							<CookiePresentation
								title={Util.loc(
									"Cookies strictement nécessaires"
								)}
								description={Util.loc(
									"Ils sont indispensables au bon fonctionnement du site et permettent par exemple de garder votre session active pour vous évitez de vous reconnecter à chaque fois que vous visitez notre site."
								)}
							/>
						</div>
						<div className="cookie-container">
							<CookiePresentation
								title={Util.loc("Cookies statistiques")}
								description={Util.loc(
									"Ces cookies nous aident à mesurer les modèles de trafic pour déterminer quelles zones de notre outil ont été visitées et pour mesurer les tendances. Cela nous permet de comprendre les habitudes d’un visiteur afin d’améliorer nos produits et services en ligne."
								)}
								toggle
								toggleEnableState={
									this.state.googleAnalyticsState
								}
								onToggle={this.onToggleGoogleAnalytics}
							/>
						</div>
					</div>
					<button onClick={this.props.onClose}>
						{Util.loc("Je valide")}
					</button>
				</div>
			</div>
		);
	}
}

CookiesModal.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default CookiesModal;
