import Loc from "./Loc";

const DEPLOY = 3;
const CHECKSYNTAX = true;

// Local npm
var URL_BASE = "";
var URL_SERVER = "http://127.0.0.1/spoors-srv/";
var GA_DEBUG = true; //Google Analytics Debug
var TEST = true;
var VOX_DEBUG = false;
var INTERNATIONAL = true;

if (DEPLOY === 0) {
}
if (DEPLOY === 1) {
	// Local apache
	URL_BASE = "/spoors";
	URL_SERVER = "http://127.0.0.1/spoors-srv/";
}
if (DEPLOY === 2) {
	// Local client / dev server
	URL_BASE = "";
	URL_SERVER = "https://ns3127228.ip-188-165-204.eu/srv/";
	//	VOX_DEBUG= true;
}
if (DEPLOY === 3) {
	// Prod
	URL_BASE = "";
	URL_SERVER = "https://scan.spoors.fr/srv/";
	GA_DEBUG = false;
	TEST = false;
}
if (DEPLOY === 4) {
	// Local network apache
	URL_BASE = "";
	URL_SERVER = "http://192.168.0.23/spoors-srv/";
}
if (DEPLOY === 5) {
	// Test server (same database as prod)
	URL_BASE = "";
	URL_SERVER = "https://scan.spoors.fr/test/";
	GA_DEBUG = false;
	TEST = false;
}

const VERSION = "spoors.0.0.1";

const MAX_FIELD_LENGTH = {
	firstname: 40,
	lastname: 40,
	email: 60,
	emails: 60,
	phone: 40,
	phones: 40,
	welcome: 250,
	company_name: 60,
	siret: 40,
	plate: 30,
	company_url: 120,
	tmpAddr: 60,
	floor: 5,
	address_complement: 240,
	objFamily: 5,
	objAlias: 40,
	title: 25,
	vatnumber: 13,
	code: 6,
	msg: 70,
	name: 32,
};

const SESSION_LANG = "lang";

const Util = {
	DEPLOY,
	CHECKSYNTAX: CHECKSYNTAX,
	SHOWPWD: false,
	GA_DEBUG,
	TEST,
	VOX_DEBUG,
	INTERNATIONAL,
	LANG: "fr",
	setApp: function (app) {
		this.App = app;
		this.LANG = localStorage.getItem(SESSION_LANG);
		if (!this.LANG) {
			this.LANG = navigator.language.includes("fr") ? "fr" : "en";
			localStorage.setItem(SESSION_LANG, this.LANG);
		}
	},
	setLang: function (lang) {
		this.LANG = lang;
		localStorage.setItem(SESSION_LANG, this.LANG);
		//		this.App.forceUpdate();
		window.location.reload(false); // will reload data from server that are potentially localized
	},
	loc: function (str) {
		//		return "XXX";

		let text = false;
		if (Loc[this.LANG]) text = Loc[this.LANG][str];
		if (text) return text;
		return str;
	},
	fillStr: function (str, patterns) {
		for (let k in patterns)
			str = str.split("[" + k + "]").join(patterns[k]);
		return str;
	},
	timeMs: function () {
		let d = new Date();
		return d.getTime();
	},
	nicePhone(str) {
		str = str.split(" ").join("").slice(0, 10);
		let out = "";
		for (let i = 0; i < str.length; i += 2) out += str.substr(i, 2) + " ";
		return out.trim(); // remove final space
	},
	nice(s, field) {
		//		console.log(s, field);
		if (typeof s !== "string") return s;
		if (MAX_FIELD_LENGTH[field]) s = s.slice(0, MAX_FIELD_LENGTH[field]);

		if (field === "callback") return s;
		if (
			field === "email" ||
			field === "emails" ||
			field === "phone" ||
			field === "phones" ||
			field === "title" ||
			field === "siret" ||
			field === "plate"
		)
			s = s.trim();
		if (s === "") return s;
		if (field === "floor") {
			let v = parseInt(s);
			if (v > 0) return "" + v;
			return "";
		}
		if (field === "email" || field === "emails" || field === "company_url")
			s = s.toLowerCase();
		if (field === "email" || field === "emails" || field === "company_url")
			return s;
		if (field === "phone" || field === "phones") return this.nicePhone(s);
		if (field === "plate") return s.toUpperCase().slice(0, 10);
		if (field === "siret") return s.slice(0, 14);
		if (field === "vatnumber") return s.split(" ").join("").toUpperCase();
		if (field === "address_type") return s;
		return s.charAt(0).toUpperCase() + s.slice(1);
	},
	check: function (str, emptyOK) {
		if (emptyOK && str === "") return;
		if (typeof str === "undefined" || str === "")
			return { display: "block" };
	},
	checkVat: function (str) {
		if (str === "") return;
		var re = /^[\w]{2}[\d]{11}$/;
		//		console.log("checkPhone",re.test(String(str)));
		if (!re.test(String(str).toLowerCase())) return { display: "block" };
	},
	checkDelay: function (val) {
		if (typeof val === "undefined" || val <= 0) return { display: "block" };
	},
	checkNonZero: function (val) {
		if (typeof val === "undefined" || val === 0)
			return { display: "block" };
	},
	checkUrl: function (str) {
		return;
		//		if ((typeof str==='undefined')||(str==="")) return;
		//		str=str.toLowerCase();
		//		if (str.startsWith("http://".slice(0,str.length))) return;
		//		if (str.startsWith("https://".slice(0,str.length))) return;
		//		return {display:"block"};
	},
	checkLabel: function (str, except, emptyOK) {
		if (emptyOK && str === "") return;
		if (
			typeof str === "undefined" ||
			str === "" ||
			str.trim().toLowerCase() === except.toLowerCase()
		)
			return { display: "block" };
	},
	checkEmail: function (str, emptyOK) {
		if (emptyOK && str === "") return;
		if (typeof str === "undefined" || str === "")
			return { display: "block" };
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!re.test(String(str).toLowerCase())) return { display: "block" };
	},
	countryFromPhone: function (number) {
		try {
			if (number.length < 6) return "FR";
			if (number.startsWith("0")) return "FR";
			if (number.startsWith("+590")) return "GP";
			if (number.startsWith("+262")) return "YT";
			if (number.startsWith("+212")) return "MA";
			if (number.startsWith("+")) {
				const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
				let numberProto = phoneUtil.parse(number, "");
				let countryCode = phoneUtil.getRegionCodeForNumber(numberProto);
				if (countryCode !== null) return countryCode;
			}
		} catch (e) {}
		return this.App.myCountry();
	},
	niceInternationalPhone: function (number, countryCode) {
		if (!countryCode) countryCode = this.countryFromPhone(number);
		//		console.log("niceInternationalPhone", number, countryCode);
		number = number.split(" ").join("");
		number = number.split("(").join("");
		number = number.split(")").join("");
		number = number.split("-").join("");
		if (typeof number === "undefined" || number.length < 6) return number;
		if (number.length >= 20) return number.substr(0, 20);

		try {
			const PNF = require("google-libphonenumber").PhoneNumberFormat;
			const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
			let numberProto = phoneUtil.parse(number, countryCode);
			if (!phoneUtil.isValidNumber(numberProto)) return number;
			let numInterna = phoneUtil.format(numberProto, PNF.INTERNATIONAL);
			console.log("-> niceInternumInternanationalPhone", numInterna);
			return numInterna;
		} catch (e) {}
		return number;
	},
	checkInternationalPhone: function (number, countryCode, emptyOK) {
		const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
		if (!countryCode) countryCode = this.countryFromPhone(number);

		//		console.log("checkInternationalPhone: ", number, ", ", countryCode);
		if (emptyOK && number === "") return;

		if (
			typeof number === "undefined" ||
			number.length < 6 ||
			number.length >= 20
		)
			return { display: "block" };

		try {
			let numberProto = phoneUtil.parse(number, countryCode);
			//			console.log("numberProto", numberProto);
			if (phoneUtil.isValidNumber(numberProto)) return;
		} catch (e) {}
		console.log("notvalid");
		return { display: "block" };
	},
	checkSmsNumber: function (number, countryCode) {
		const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
		if (!countryCode) countryCode = this.countryFromPhone(number);

		console.log("is SMS: ", number, ", ", countryCode);
		if (number === "") return false;
		if (
			typeof number === "undefined" ||
			number.length < 6 ||
			number.length >= 20
		)
			return { display: "block" };

		try {
			let numberProto = phoneUtil.parse(number, countryCode);
			if (phoneUtil.isValidNumber(numberProto)) return;
			let phoneNumberType = phoneUtil.getNumberType(numberProto);
			if (phoneNumberType === 1 || countryCode === "US") return false;
		} catch (e) {}
		return { display: "block" };
	},
	checkPhone: function (str, emptyOK) {
		if (emptyOK && str === "") return;
		if (typeof str === "undefined" || str === "")
			return { display: "block" };
	},
	checkSiret: function (str, emptyOK) {
		if (emptyOK && str === "") return;
		if (typeof str === "undefined" || str === "")
			return { display: "block" };
		//		var re = /^\+?[\d ]{6,16}$/;
		var re = /^[\d]{14}$/;
		//		console.log("checkPhone",re.test(String(str)));
		if (!re.test(String(str).toLowerCase())) return { display: "block" };
	},
	checkPwd: function (str1, str2, emptyOK) {
		if (emptyOK && str2 === "") return;
		if (str1 !== str2) return { display: "block" };
	},
	nextButton: function (val) {
		if (val) return "btn btn-secondary btn-block";
		return "btn btn-primary btn-block";
	},
	nextButtonClassic: function (val) {
		if (val) return "btn blueOpacity-bg btn-block";
		return "btn blueOk-bg btn-block";
	},
	nextButtonSmall: function (val, extra) {
		if (!extra) extra = "";
		if (val) return "btn btn-secondary btn-small " + extra;
		return "btn btn-primary btn-small " + extra;
	},
	random: function () {
		return "id-" + Math.floor(Math.random() * 100000000);
	},
	exceptParse: function (str) {
		let result = [];
		for (let i = 0; i < str.length; i++) {
			let c = str.substr(i, 1);
			result.push(c !== "-" ? true : false);
		}
		return result;
	},
	exceptEncode: function (except) {
		let str = "";
		for (let i = 0; i < except.length; i++) str += except[i] ? "0" : "-";
		return str;
	},
	url: function (url) {
		return URL_BASE + url;
	},
	itoa2: function (v) {
		if (v < 10) return "0" + v;
		return "" + v;
	},
	timeFromMinutes: function (v) {
		return this.itoa2(Math.floor(v / 60)) + ":" + this.itoa2(v % 60);
	},
	timeToMinutes: function (v) {
		let p = v.split(":");
		return parseInt(p[0]) * 60 + parseInt(p[1]);
	},
	getProvider() {
		const provider = window.sessionStorage.getItem("provider");
		if (provider) {
			return JSON.parse(provider);
		}
		return false;
	},
	getProviderExt() {
		const provider = this.getProvider();
		if (provider) return provider;
		return { name: "", operation: "", url: false };
	},
	setProvider(val) {
		const PROVIDERS_REDIRECTION = {
			ENGIE: {
				DIGITAL: "https://particuliers.engie.fr/demenagement.html", // Digital source
				MAIL: "https://particuliers.engie.fr/demenagement.html", //Mail source
			},
		};

		if (
			val &&
			PROVIDERS_REDIRECTION[val.name] &&
			PROVIDERS_REDIRECTION[val.name][val.operation]
		)
			val.url = PROVIDERS_REDIRECTION[val.name][val.operation];
		else val = false;
		window.sessionStorage.setItem("provider", JSON.stringify(val));
	},

	screenWidth() {
		return document.getElementsByTagName("body")[0].getBoundingClientRect()
			.width;
	},

	VERSION: VERSION,
	URL: URL_SERVER,

	HOME: URL_BASE + "/",
	HOME_BASE: URL_BASE + "/HOME",
	HOME_FAQ: URL_BASE + "/HOME_FAQ",
	HOME_WHO: URL_BASE + "/HOME_WHO",
	HOME_CONTACT: URL_BASE + "/HOME_CONTACT",
	HOME_CGV: URL_BASE + "/HOME_CGV",
	HOME_ERROR: URL_BASE + "/HOME_ERROR",
	HOME_SIGNIN: URL_BASE + "/HOME_SIGNIN",
	CONFIRM: URL_BASE + "/CONFIRM",

	PURCHASE_START: URL_BASE + "/PURCHASE_START",
	PURCHASE_FORM: URL_BASE + "/PURCHASE_FORM",
	PURCHASE_SIGNIN: URL_BASE + "/PURCHASE_SIGNIN",
	PURCHASE_CONFIRM: URL_BASE + "/PURCHASE_CONFIRM",
	PURCHASE_PAY: URL_BASE + "/PURCHASE_PAY",
	PURCHASE_DONE: URL_BASE + "/PURCHASE_DONE",
	PURCHASE_UNITS: URL_BASE + "/PURCHASE_UNITS",
	SELECT_PARTNERS: URL_BASE + "/SELECT_PARTNERS",
	BELL: URL_BASE + "/BELL",
	CLAIM: URL_BASE + "/CLAIM",
	CLAIM_USAGE: URL_BASE + "/CLAIM_USAGE",
	CLAIM_FORM: URL_BASE + "/CLAIM_FORM",
	CLAIM_SIGNIN: URL_BASE + "/CLAIM_SIGNIN",
	CLAIM_DONE: URL_BASE + "/CLAIM_DONE",
	CLAIM_ALERTS: URL_BASE + "/CLAIM_ALERTS",
	CLAIM_ALERT: URL_BASE + "/CLAIM_ALERT",
	CLAIM_WELCOME: URL_BASE + "/CLAIM_WELCOME",
	VISIT: URL_BASE + "/VISIT",
	VISIT_MSG: URL_BASE + "/VISIT_MSG",
	VISIT_DONE: URL_BASE + "/VISIT_DONE",
	VISIT_AUTO: URL_BASE + "/VISIT_AUTO",
	BOARD: URL_BASE + "/BOARD",
	BELL_EDIT: URL_BASE + "/BELL_EDIT",
	BELL_ALERT: URL_BASE + "/BELL_ALERT",
	BELL_REDIRECT_DONE: URL_BASE + "/BELL_REDIRECT_DONE",
	CONTACTS: URL_BASE + "/CONTACTS",
	CONTACT_EDIT: URL_BASE + "/CONTACT_EDIT",
	CONTACT_ROLE: URL_BASE + "/CONTACT_ROLE",
	ACCOUNT: URL_BASE + "/ACCOUNT",
	ACCOUNT_EDIT: URL_BASE + "/ACCOUNT-EDIT",
	ACCOUNT_INVOICES: URL_BASE + "/ACCOUNT_INVOICES",
	ACCOUNT_CONSUM: URL_BASE + "/ACCOUNT_CONSUM",
	SCAN: URL_BASE + "/SCAN",
	PAGE_TEST: URL_BASE + "/TEST",
	FROM: URL_BASE + "/FROM",

	BELL_STD: "std",
	BELL_BACK: "back",
	BELL_PAPER: "paper",
	BELL_WEAR: "wear",
	BELL_PAPER1010: "paper1010",
	BELL_HOLE: "hole",
	BELL_KEYCHAIN: "keychain",
	BELL_MEDAL: "medal",
	BELL_WA: "whatsapp",

	ADDRESS_PRIMARY: "primary",
	ADDRESS_SECONDARY: "secondary",

	TYPE_INDIVIDUAL: 1,
	TYPE_PRO: 2,
	GENDER_FEMALE: 1,
	GENDER_MALE: 2,

	LEVEL_REGISTERED: 100,
	LEVEL_ADMIN: 200,

	ERROR_ANY: -1,
	ERROR_SIGNINFIRST: -2,

	MENU_ACTIVE: "ACTIVE",
	MENU_VISIT: "VISIT",
	MENU_MOVE: "MOVE",
	MENU_WELCOME: "WELCOME",
	MENU_STOP: "STOP",
	MENU_ALERTS: "ALERTS",

	MENU_IDENTITY: "IDENTITY",
	MENU_PWD: "PWD",
	MENU_MOVING: "MOVING",

	ROLE_NONE: "none",
	ROLE_USER: "user",
	ROLE_OWNER: "owner",

	MAIL_MSG_MAX_SIZE: 200,
	SMS_MSG_MAX_SIZE: 70,

	ALERT_PHONE: 1,
	ALERT_EMAIL: 2,
	ALERT_SMS: 4,

	ALERT_FIRST: -2,
	ALERT_LAST: -1,

	USAGE_CAR: "car",
	USAGE_BUILDING: "building",
	USAGE_OBJECT: "object",
	USAGE_BOX: "box",
	USAGE_VAN: "van",
	USAGE_KEYCHAIN: "keychain",
	USAGE_PET: "pet",
	USAGE_BAG: "bag",
	USAGE_CLOTHES: "clothes",
	USAGE_PLACE: "place",
	USAGE_TOY: "toy",

	POLICY_ANONYMOUS_ALWAYS: 0,
	POLICY_ANONYMOUS_BEST: 1,
	POLICY_ANONYMOUS_NEVER: 2,

	HTTP_TIMEOUT: 20000,
	CALL_TIMEOUT: 5 * 60 - 5,

	SPOORS_BELL: "SPOORS_BELL",
	SPOORS_VIGNETTE: "SPOORS_VIGNETTE",
	SPOORS_TAG: "SPOORS_TAG",
	ENGIE_BELL: "ENGIE_BELL",
	ENGIE_VIGNETTE: "ENGIE_VIGNETTE",

	VERSION_CLIENT: 100,

	objFamilies: function () {
		return {
			"": Util.loc("Le type de mon objet *"),
			PC: Util.loc("ordinateur portable"),
			PHONE: Util.loc("smartphone"),
			ID: Util.loc("carte d’identité"),
			PASS: Util.loc("passeport"),
			LIC: Util.loc("permis de conduire"),
			NAV: Util.loc("carte de transport"),
			CASE: Util.loc("valise"),
			OTH: Util.loc("autre"),
		};
	},
	timeShortMonthYear: function (t) {
		const monthNames = [
			Util.loc("Jan"),
			Util.loc("Fév"),
			Util.loc("Mars"),
			Util.loc("Avril"),
			Util.loc("Mai"),
			Util.loc("Juin"),
			Util.loc("Juil"),
			Util.loc("Août"),
			Util.loc("Sept"),
			Util.loc("Oct"),
			Util.loc("Nov"),
			Util.loc("Déc"),
		];
		let d = new Date(t * 1000);
		let timeStr = monthNames[d.getMonth()] + " " + d.getFullYear();
		return timeStr;
	},
	timeShortFormat: function (t) {
		const monthNames = [
			Util.loc("Jan"),
			Util.loc("Fév"),
			Util.loc("Mars"),
			Util.loc("Avril"),
			Util.loc("Mai"),
			Util.loc("Juin"),
			Util.loc("Juil"),
			Util.loc("Août"),
			Util.loc("Sept"),
			Util.loc("Oct"),
			Util.loc("Nov"),
			Util.loc("Déc"),
		];
		let d = new Date(t * 1000);
		let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
		let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
		let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
		let timeStr =
			day + " " + monthNames[d.getMonth()] + " - " + hours + ":" + min;
		return timeStr;
	},
	timeFormat: function (t) {
		const monthNames = [
			Util.loc("Janvier"),
			Util.loc("Février"),
			Util.loc("Mars"),
			Util.loc("Avril"),
			Util.loc("Mai"),
			Util.loc("Juin"),
			Util.loc("Juillet"),
			Util.loc("Août"),
			Util.loc("Septembre"),
			Util.loc("Octobre"),
			Util.loc("Novembre"),
			Util.loc("Décembre"),
		];
		let d = new Date(t * 1000);
		let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
		let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
		let timeStr =
			d.getDate() +
			" " +
			monthNames[d.getMonth()] +
			" " +
			Util.loc("à") +
			" " +
			hours +
			":" +
			min;
		return timeStr;
	},

	timeFormat2: function (t) {
		const monthNames = [
			Util.loc("Janvier"),
			Util.loc("Février"),
			Util.loc("Mars"),
			Util.loc("Avril"),
			Util.loc("Mai"),
			Util.loc("Juin"),
			Util.loc("Juillet"),
			Util.loc("Août"),
			Util.loc("Septembre"),
			Util.loc("Octobre"),
			Util.loc("Novembre"),
			Util.loc("Décembre"),
		];
		let d = new Date(t * 1000);
		let min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
		let hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
		let timeStr =
			d.getDate() +
			" " +
			monthNames[d.getMonth()] +
			" - " +
			hours +
			":" +
			min;
		return timeStr;
	},

	dateFormat: function (t) {
		const monthNames = [
			Util.loc("Janvier"),
			Util.loc("Février"),
			Util.loc("Mars"),
			Util.loc("Avril"),
			Util.loc("Mai"),
			Util.loc("Juin"),
			Util.loc("Juillet"),
			Util.loc("Août"),
			Util.loc("Septembre"),
			Util.loc("Octobre"),
			Util.loc("Novembre"),
			Util.loc("Décembre"),
		];
		let d = new Date(t * 1000);
		let timeStr;
		if (this.LANG === "fr")
			timeStr = d.getDate() + " " + monthNames[d.getMonth()];
		else timeStr = monthNames[d.getMonth()] + " " + d.getDate();
		return timeStr;
	},

	getYear: function (t) {
		let d = new Date(t * 1000);
		let yearStr = d.getFullYear();
		return yearStr;
	},

	getMonth: function (t) {
		const monthNames = [
			Util.loc("Janvier"),
			Util.loc("Février"),
			Util.loc("Mars"),
			Util.loc("Avril"),
			Util.loc("Mai"),
			Util.loc("Juin"),
			Util.loc("Juillet"),
			Util.loc("Août"),
			Util.loc("Septembre"),
			Util.loc("Octobre"),
			Util.loc("Novembre"),
			Util.loc("Décembre"),
		];
		let d = new Date(t * 1000);
		let timeStr = monthNames[d.getMonth()];
		return timeStr;
	},

	dayofWeek(i) {
		let data = [
			this.loc("Dimanche"),
			this.loc("Lundi"),
			this.loc("Mardi"),
			this.loc("Mercredi"),
			this.loc("Jeudi"),
			this.loc("Vendredi"),
			this.loc("Samedi"),
		];
		return data[i];
	},
	dayofWeekBell(i) {
		let data = [
			this.loc("Dim"),
			this.loc("Lun"),
			this.loc("Mar"),
			this.loc("Mer"),
			this.loc("Jeu"),
			this.loc("Ven"),
			this.loc("Sam"),
		];
		return data[i];
	},
	schedule(alert) {
		let days = "";
		if (alert.except === "-------") days = Util.loc("Tous les jours");
		else if (alert.except === "0-----0") days = Util.loc("En semaine");
		else if (alert.except === "-00000-") days = Util.loc("Le week-end");
		else if (alert.except === "0000000") return Util.loc("Jamais");
		else {
			let detail = [];
			for (let i = 0; i < 7; i++)
				if (alert.except[i] == "-") detail.push(Util.dayofWeekBell(i));
			days = detail.join(", ");
		}
		if (alert.from === 0 && alert.to === 0) return days;
		days +=
			" " +
			this.fillStr(Util.loc("de [from] à [to]"), {
				from: this.timeFromMinutes(alert.from),
				to: this.timeFromMinutes(alert.to),
			});
		return days;
	},
	nicePrice(val) {
		if (!val) return Util.loc("offert");
		return (val / 100).toFixed(2) + " €TTC";
	},
	nicePlate(val) {
		if (val.length !== 8) return val;
		return (
			val.substr(0, 2) + "-" + val.substr(2, 4) + "-" + val.substr(6, 2)
		);
	},
	bellTitle(elem) {
		return elem.detail.title;
	},
	// isMobile() {
	// 	return window.screen.availWidth <= 420;
	// },
	isMobile() {
		//		return true;
		return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	},
};

export default Util;
