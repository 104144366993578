import React from "react";
import { Link } from "react-router-dom";
import Proptypes from "prop-types";

import Util from "./lib/Util";

import Page from "./Components/Page";

class AccountEmailConfirm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			msg: Util.loc("... vérification en cours ..."),
			next: false,
		};

		this.props.App.confirm(this.props.data, result => {
			console.log("AccountEmailConfirm result");
			console.log(result);
			let msg = Util.loc("Votre adresse mail est maintenant validée");
			let next = Util.BOARD;
			if (result.err) msg = result.msg;
			console.log(msg);
			this.setState({
				msg: msg,
				next: next,
			});
		});
	}
	render() {
		const { App } = this.props;

		let next = Util.HOME;
		if (this.props.App.getBellNb() > 0) next = Util.BOARD;

		return (
			<Page App={App} withoutHeader>
				<div className="claimBackground row containt text-center">
					<section className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto pt-block scan">
						<div className="p-2 mx-auto">
							<p className="text-center mb-4 mt-4 comfirm_msg">
								{this.state.msg}
							</p>
						</div>
						<div className="col-10 mx-auto">
							{this.state.next ? (
								<Link to={next}>
									<button
										href="#"
										className="btn btn-primary btn-block mb-2"
									>
										{Util.loc("Continuer")}
									</button>
								</Link>
							) : null}
						</div>
					</section>
				</div>
			</Page>
		);
	}
}

AccountEmailConfirm.propTypes = {
	App: Proptypes.object.isRequired,
};

export default AccountEmailConfirm;
