import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import Api from "./lib/Api";

import Page from "./Components/Page";
import ButtonSuivant from "./Components/ButtonSuivant";

// Components
import BoutonRevenir from "./Components/BoutonRevenir";

class ClaimSignin extends React.Component {
	constructor(props) {
		super(props);
		props.App.storyNeed("/CLAIM", Util.HOME);
		this.state = this.props.App.storyGet({ email: "", code: "" });
		this.state.askCode = this.state.email.length ? true : false;
		this.state.resend = true;
	}
	changeField(value, field) {
		let update = { [field]: Util.nice(value, field) };
		this.setState(update);
		this.props.App.storyUpdate(update);
	}
	reSendCode() {
		this.setState({ resend: false });
		this.sendCode(true);
	}
	sendCode(force) {
		if (!force) {
			if (!this.checkButton()) return;
			if (this.state.code !== "")
				return this.props.App.signin(
					this.state.email,
					this.state.code,
					result => {
						let phone = this.props.App.myFirstPhone();
						if (phone) this.props.App.storyUpdate({ phone: phone });
						this.props.App.storyProcess();
					}
				);
		}
		new Api().fetch("sendCode", { email: this.state.email }, result => {
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.setState({ askCode: true });
		});
	}
	checkButton() {
		if (Util.checkEmail(this.state.email)) return false;
		if (!this.state.askCode) return true;
		if (this.state.code.length !== 6) return false;
		return true;
	}
	renderButton() {
		return (
			<>
				<div className="row d-flex flex-row justify-content-center">
					<div className="col-12 mt-30">
						<div className="row btnRevenirSuivant withNext">
							<BoutonRevenir to={Util.CLAIM_FORM} />
							<ButtonSuivant
								onClick={() => this.sendCode()}
								enabled={this.checkButton()}
								className="step"
								final={true}
							>
								{Util.loc("Connexion")}
							</ButtonSuivant>
						</div>
					</div>
				</div>
			</>
		);
	}

	render() {
		const { App } = this.props;
		return (
			<Page
				App={App}
				title={Util.loc("Etape 3 :")}
				titleH2={Util.loc("mon compte")}
				stepClass="title-containerEtape"
				claimTitle="claimTitle"
			>
				<section id="sign-in" className="claimBackground">
					<div className="col-12 col-sm-10 col-md-8 col-6 claimPadding20 m-auto">
						<div className="container">
							<div className="row">
								<div
									className="col-12 col-sm-10 col-lg-6 m-auto needs-validation"
									noValidate
								>
									{this.state.askCode ? (
										<>
											<p className="color-title mb-3">
												{Util.loc(
													"Je saisis le code à 6 chiffres que je viens de recevoir par email à cette adresse :"
												)}
												<br />
												<br />
												<span>{this.state.email}</span>
											</p>
											<p>
												<small>
													{Util.loc(
														"(à noter : l’email peut mettre plus d’une minute à arriver. Pensez aussi à vérifier dans votre courrier indésirable)"
													)}
												</small>
											</p>
										</>
									) : (
										<p className="infoAlert mb-5">
											{Util.loc(
												"Je saisis mon email pour rattacher la sonnette à mon compte"
											)}
										</p>
									)}
									<section className="spaceSection">
										{/* <div className="form-row"> */}
										<div className="">
											<div className="w-100">
												{this.state.askCode ? (
													<div className="row form-group">
														<div className="col-12 px-0">
															<input
																type="text"
																className="form-control formControlStep mb-3"
																id="code"
																placeholder={Util.loc(
																	"Le code à 6 chiffres"
																)}
																value={
																	this.state
																		.code
																}
																onKeyPress={event => {
																	if (
																		event.key ===
																		"Enter"
																	)
																		event.target.blur();
																}}
																onChange={event => {
																	this.changeField(
																		event
																			.target
																			.value,
																		"code"
																	);
																}}
															/>
															<label htmlFor="code">
																{Util.loc(
																	"Le code à 6 chiffres"
																)}
															</label>
														</div>
													</div>
												) : (
													<div className="row form-group">
														<div className="col-12 px-0">
															<input
																type="text"
																className="form-control formControlStep mb-3"
																id="email"
																placeholder={Util.loc(
																	"Mon email *"
																)}
																value={
																	this.state
																		.email
																}
																onKeyPress={event => {
																	if (
																		event.key ===
																		"Enter"
																	)
																		event.target.blur();
																}}
																onChange={event => {
																	this.changeField(
																		event
																			.target
																			.value,
																		"email"
																	);
																}}
															/>
															<label htmlFor="email">
																{Util.loc(
																	"Mon email"
																)}
															</label>
															<div
																style={Util.checkEmail(
																	this.state
																		.email,
																	true
																)}
																className="invalid-tooltip"
															>
																{Util.loc(
																	"Veuillez remplir ce champ correctement"
																)}
															</div>
														</div>
													</div>
												)}
											</div>
										</div>
									</section>
									<div>{this.renderButton()}</div>
									{this.state.resend && this.state.askCode ? (
										<p className="text-center mt-5">
											<a
												className="forgetPassword"
												onClick={() =>
													this.reSendCode()
												}
											>
												{Util.loc(
													"Renvoyer le code par email"
												)}
											</a>
										</p>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</section>
			</Page>
		);
	}
}

ClaimSignin.propTypes = {
	App: Proptypes.object.isRequired,
};

export default ClaimSignin;
