import React from "react";

import Util from "./lib/Util";
import TopMenu from "./Components/TopMenu";
import Footer from "./Components/Footer";
import IcoPresent from "./style/img/ico-present.png";

class SelectPartners extends React.Component {
	constructor(props) {
		super(props);

		this.props.App.storyNeedMulti(
			["/CLAIM", "/PURCHASE", "/ACCOUNT_UPDATE"],
			Util.HOME
		);

		this.partners = this.props.App.partnersGet();
		//		this.partners={"ENGIE":"ENGIE","LAPOSTE":"La Poste","OTHER":"foobar"};

		let story = this.props.App.storyGet();
		this.immediate = story.url === "/ACCOUNT_UPDATE";

		let prev = story[props.optin];
		let selection = {};
		for (let key in this.partners) {
			let v = typeof prev === "boolean" ? prev : prev[key];
			selection[key] = v ? true : false;
		}
		console.log(selection);
		this.state = {
			selection: selection,
		};
		console.log(this.state);
	}
	changeBox(event, field) {
		let selection = this.state.selection;
		selection[field] = event;
		console.log(selection);
		this.setState({ selection: selection });
	}
	selectUpdate() {
		let update = {};
		let selection = {};
		let empty = true;
		for (var key in this.state.selection)
			if (this.state.selection[key]) {
				selection[key] = true;
				empty = false;
			}
		if (empty) selection = false;
		update[this.props.optin] = selection;
		this.props.App.storyUpdate(update);
		if (this.immediate)
			return this.props.App.storyProcess(() => {
				this.props.App.goBack();
			});
		this.props.App.goBack();
	}
	goNext(validate) {
		if (validate) return this.selectUpdate();
		this.props.App.goBack();
	}
	render() {
		return (
			<React.Fragment>
				<TopMenu App={this.props.App}></TopMenu>
				<div className="row position-relative containt">
					<div className="bg-primary fix"></div>
					<h1 className="text-light col-12 text-center">
						{this.props.optin === "optinReceive"
							? Util.loc("Nos partenaires")
							: Util.loc("Droits de mes données")}
					</h1>
					<div className="col-12 col-sm-10 col-md-8 col-lg-6 m-auto text-center">
						<div className="ico-title m-auto">
							<img src={IcoPresent} alt="cadeaux" />
						</div>
						<div className="row">
							<div className="col-12">
								<div>
									<div className="row">
										<div className="col-12">
											<p>
												{this.props.optin ===
												"optinReceive"
													? Util.loc(
															"Je sélectionne les partenaires pour lesquels j’accepte que SPOORS transmette mes données afin de recevoir des recommandations adaptées à ma situation"
													  )
													: Util.loc(
															"Je sélectionne les partenaires pour lesquels j’accepte que SPOORS transmette mes données afin de qualifier leurs bases clients visant à des actions non commerciales."
													  )}
											</p>
										</div>
									</div>
									<div className="text-center col-sm-10 m-auto">
										{Object.keys(this.partners).map(
											(key, i) => (
												<div
													className="row mt-4"
													key={i}
												>
													<div className="col-8 m-auto">
														<div className="custom-control custom-checkbox">
															<input
																type="checkbox"
																className="form-check-input basic"
																name={
																	"partner" +
																	i
																}
																id={
																	"partner" +
																	i
																}
																checked={
																	this.state
																		.selection[
																		key
																	]
																}
																onChange={event => {
																	this.changeBox(
																		event
																			.target
																			.checked,
																		key
																	);
																}}
															/>
															<label
																className="custom-control-label text-left"
																htmlFor={
																	"partner" +
																	i
																}
															></label>
															<img
																src={Util.url(
																	"/" +
																		key +
																		".png"
																)}
																height="42"
																alt=""
															/>
														</div>
													</div>
												</div>
											)
										)}
									</div>
								</div>

								<div className="row">
									<div className="col-sm-10 mx-auto d-flex justify-content-around px-0">
										<button
											className="btn btn-secondary btn-small"
											onClick={() => this.goNext(false)}
										>
											{Util.loc("non merci")}
										</button>
										<button
											className="btn btn-primary btn-small"
											onClick={() => this.goNext(true)}
										>
											{Util.loc("je valide")}
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer></Footer>
			</React.Fragment>
		);
	}
}
export default SelectPartners;
