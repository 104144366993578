import React from "react";
import Proptypes from "prop-types";

import Api from "./lib/Api.js";
import Util from "./lib/Util.js";

import Page from "./Components/Page";

class Bell extends React.Component {
	constructor(props) {
		super(props);
		let date = new Date();
		let start = date.getHours() * 60 + date.getMinutes();
		let day = date.getDay();

		let data = { code: props.code, start: start, day: day };
		new Api().fetch("scan", data, result => {
			console.log(result);
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			props.App.handleScan(result);
		});
	}
	render() {
		const { App } = this.props;
		return (
			<Page App={App} withoutHeader>
				<div className="row containt text-center claimBackground">
					<section className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto pt-5 scan">
						<div className="p-5 mx-auto color-title">
							<p className="text-center font-weight-regular mb-4 mt-4">
								{Util.loc("... recherche du SPOORS")}
							</p>
							<p className="text-center font-weight-regular mb-4 mt-4">
								{Util.loc("veuillez patienter")}
							</p>
						</div>
					</section>
				</div>
			</Page>
		);
	}
}

Bell.propTypes = {
	App: Proptypes.object.isRequired,
};

export default Bell;
