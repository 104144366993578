import * as VoxImplant from "voximplant-websdk";
import Util from "./Util";

const sdk = VoxImplant.getInstance();

const STAGE_IDLE = 0;
const STAGE_INIT = 1;
const STAGE_CONNECT = 2;
const STAGE_LOGIN = 3;
const STAGE_READY = 4;
const STAGE_REACHING = 5;
const STAGE_TALKING = 6;

const Call = {
	ERR_CONNECT: "ERR_CONNECT",
	ERR_CALLING: "ERR_CALLING",
	ERR_FAILED: "ERR_FAILED",
	OK_CONNECTED: "OK_CONNECTED",
	OK_DISCONNECTED: "OK_DISCONNECTED",
	OK_TONE_START: "OK_TONE_START",
	OK_TONE_STOP: "OK_TONE_STOP",
	stage: STAGE_IDLE,
	initialized: false,
	call: false,
	setStage(val) {
		this.stage = val;
		console.log("-->stage " + val);
	},
	init(user, pwd, cbReady) {
		this.user = user;
		this.pwd = pwd;
		this.cbReady = cbReady;
		if (this.initialized) return this.connect(true);
		console.log("SDK init");
		this.setStage(STAGE_INIT);
		sdk.init({ showDebugInfo: Util.VOX_DEBUG });
		sdk.on(VoxImplant.Events.SDKReady, e => {
			console.log("SDK initialized");
			this.initialized = true;
			if (this.stage !== STAGE_INIT) return this.hangup();
			this.connect(true);
		});
		sdk.on(VoxImplant.Events.ACDError, e => {
			console.log("SDK ACDError");
			this.cbReady(e);
		});
		sdk.on(VoxImplant.Events.MicAccessResult, e => {
			console.log("SDK MicAccessResult");
			//			console.log(e);
			if (!e.result) this.cbReady(e);
		});
		const streamManager = VoxImplant.Hardware.StreamManager.get();

		streamManager.on(
			VoxImplant.Hardware.HardwareEvents.MediaRendererAdded,
			e => {
				console.log("SDK HardwareEvents.MediaRendererAdded");
				//https://voximplant.com/docs/references/websdk/voximplant/hardware/streammanager#get
				//https://voximplant.com/docs/howtos/calls/video#beforemediarendererremoved
				//			let localNode = document.getElementById('local_ui');
				//			e.renderer.render(localNode);
			}
		);
		sdk.on(VoxImplant.Events.AuthResult, e => {
			if (e.code) {
				console.log(e.code, e);
				this.cbReady(e);
			} else {
				console.log("User logged in Voximplant", e.code);
				if (this.stage !== STAGE_LOGIN) return this.hangup();
				this.setStage(STAGE_READY);
				this.cbReady();
			}
		});
	},
	connect() {
		console.log("SDK connect");
		this.setStage(STAGE_CONNECT);
		sdk.connect()
			.then(() => {
				console.log("SDK connected, now logging in");
				if (this.stage !== STAGE_CONNECT) return this.hangup();
				this.setStage(STAGE_LOGIN);
				sdk.login(this.user, this.pwd);
			})
			.catch(err => {
				console.log("SDK connection failed");
				this.cbReady({ name: "NetworkUnreachable" });
			});
	},

	callstart(user, pwd, callee, cb) {
		if (this.stage !== STAGE_IDLE) return cb(this.ERR_CALLING);
		this.init(user, pwd, e => {
			if (e) {
				this.hangup();
				return cb(this.ERR_CONNECT, e);
			}
			this.setStage(STAGE_REACHING);
			this.call = false;
			this.call = sdk.call(callee);
			this.call.on(VoxImplant.CallEvents.Failed, e => {
				console.log(
					`error code : ${e.code}\nerror reason : ${
						e.reason
					}\nstate : ${e.call.state()}\nactive : ${e.call.active()}\nnumber : ${e.call.number()}\nid : ${e.call.id()}\ndisplay name : ${e.call.displayName()}\nduration : ${e.call.getCallDuration()}`
				);
				this.hangup();
				cb(this.ERR_FAILED, e);
			});
			this.call.on(VoxImplant.CallEvents.Connected, e => {
				console.log(`Call connected to ${callee}`);
				this.setStage(STAGE_TALKING);
				cb(this.OK_CONNECTED, e);
			});
			this.call.on(VoxImplant.CallEvents.Disconnected, e => {
				console.log(`Call disconnected`);
				this.setStage(STAGE_READY);
				this.hangup();
				cb(this.OK_DISCONNECTED, e);
			});
			this.call.on(VoxImplant.CallEvents.ProgressToneStart, event => {
				console.log(`Call ProgressToneStart`);
				cb(this.OK_TONE_START, e);
			});
			this.call.on(VoxImplant.CallEvents.ProgressToneStop, event => {
				console.log(`Call ProgressToneStop`);
				cb(this.OK_TONE_STOP, e);
			});
			this.call.on(VoxImplant.CallEvents.CallStatsReceived, event =>
				console.log(event)
			);
			this.call.on(VoxImplant.CallEvents.ICECompleted, event =>
				console.log(event)
			);
			this.call.on(VoxImplant.CallEvents.ICETimeout, event =>
				console.log(event)
			);
			this.call.on(VoxImplant.CallEvents.InfoReceived, event =>
				console.log(event)
			);
			this.call.on(
				VoxImplant.CallEvents.QualityIssueCodecMismatch,
				event => console.log(event)
			);
			this.call.on(
				VoxImplant.CallEvents.QualityIssueHighMediaLatency,
				event => console.log(event)
			);
			this.call.on(
				VoxImplant.CallEvents.QualityIssueICEDisconnected,
				event => console.log(event)
			);
			this.call.on(VoxImplant.CallEvents.QualityIssuePacketLoss, event =>
				console.log(event)
			);
			this.call.on(VoxImplant.CallEvents.RTCStatsReceived, event =>
				console.log(event)
			);
			this.call.on(VoxImplant.CallEvents.StateUpdated, event =>
				console.log(event)
			);
		});
	},
	hangup() {
		if (this.stage < STAGE_REACHING) {
			console.log("Hangup before reaching");
			//if (this.stage>STAGE_CONNECT)
			try {
				sdk.disconnect();
			} catch (e) {
				console.log(e);
			}
			this.setStage(STAGE_IDLE);
			return;
		}
		console.log("Hangup ongoing call");
		this.call.hangup();
	},
};
export default Call;
