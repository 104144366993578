import React, { Component } from "react";
import Proptypes from "prop-types";

import { ReactComponent as LeftArrow } from "../style/img/left-arrow.svg";
import { ReactComponent as RightArrow } from "../style/img/right-arrow.svg";

class ProductsSlideShow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentItemIndex: props.defaultIndex,
			productsLength: props.products.length,
		};
	}

	onNextClick = () => {
		const { currentItemIndex, productsLength } = this.state;
		this.setState(prevState => ({
			currentItemIndex: (currentItemIndex + 1) % productsLength,
		}));
	};

	onBackClick = () => {
		const { currentItemIndex, productsLength } = this.state;
		this.setState(prevState => ({
			currentItemIndex:
				(currentItemIndex + productsLength - 1) % productsLength,
		}));
	};

	render() {
		const { currentItemIndex } = this.state;
		const { className } = this.props;
		const currentItem = this.props.products[currentItemIndex];
		const leftArrowClassname = "active";
		const rightArrowClassname = "active";

		return (
			<section id="products-slideshow" className={className ?? ""}>
				<div className="desktop-section">
					<LeftArrow
						onClick={this.onBackClick}
						className={leftArrowClassname}
					/>
					{currentItem}
					<RightArrow
						onClick={this.onNextClick}
						className={rightArrowClassname}
					/>
				</div>
				<div className="mobile-section">{this.props.products}</div>
			</section>
		);
	}
}

ProductsSlideShow.propTypes = {
	products: Proptypes.array.isRequired,
	className: Proptypes.string,
};

export default ProductsSlideShow;
