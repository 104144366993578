import React, { Component } from "react";
import PropTypes from "prop-types";

import { ReactComponent as Mandatory } from "../style/img/correct.svg";
import Toggle from "./Toggle";

class CookiePresentation extends Component {
	renderIcon() {
		const { toggle, toggleEnableState } = this.props;

		if (toggle) {
			return (
				<Toggle
					onChange={this.props.onToggle}
					enabled={toggleEnableState}
				/>
			);
		}

		return <Mandatory />;
	}
	render() {
		const { title, description } = this.props;

		return (
			<div className="cookie-presentation">
				<div className="textual-content">
					<h2 className="cookie-title">{title}</h2>
					<p className="cookie-description">{description}</p>
				</div>
				{this.renderIcon()}
			</div>
		);
	}
}

CookiePresentation.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	toggle: PropTypes.bool,
	toggleEnableState: PropTypes.bool,
	onToggle: PropTypes.func,
};

export default CookiePresentation;
