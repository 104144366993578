import React, { Component } from "react";
import Proptypes from "prop-types";

class HorizontalList extends Component {
	constructor(props) {
		super(props);

		this.scrollerRef = React.createRef();

		this.state = {
			hideShadow: false,
		};
	}

	componentDidMount() {
		const { withTransparency, scrollBreaker } = this.props;

		if (withTransparency) {
			this.scrollerRef.current.addEventListener("scroll", e => {
				const breaker = scrollBreaker ?? 50;
				const { hideShadow } = this.state;
				const scrollLeft = this.scrollerRef.current.scrollLeft;

				if (scrollLeft >= breaker && !hideShadow) {
					this.setState({ hideShadow: true });
				}

				if (scrollLeft < breaker && hideShadow) {
					this.setState({ hideShadow: false });
				}
			});
		}
	}

	componentWillUnmount() {
		const { withTransparency } = this.props;

		if (withTransparency) {
			this.scrollerRef.current.removeEventListener(
				"scroll",
				this.scrollerRef.current
			);
		}
	}

	render() {
		const { hideShadow } = this.state;
		const {
			id,
			className,
			withTransparency,
			withScrollVisible,
			children,
		} = this.props;

		return (
			<div
			/* className={`horizontal-list ${
					withTransparency && !hideShadow ? "should-fade" : ""
				} ${withScrollVisible ? "withScrollVisible" : ""}`} */
			>
				<div
					id={id ?? ""}
					ref={this.scrollerRef}
					className={`scroller
					 ${className ?? ""}`}
				>
					{children}
				</div>
				<div className="hl-shadow" />
			</div>
		);
	}
}

HorizontalList.propTypes = {
	id: Proptypes.string,
	className: Proptypes.string,
	withTransparency: Proptypes.bool,
	scrollBreaker: Proptypes.number,
	withScrollVisible: Proptypes.bool,
};

export default HorizontalList;
