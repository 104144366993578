import React from "react";
import { Link } from "react-router-dom";

import Util from "./lib/Util";
import Api from "./lib/Api.js";

import CenteredContainer from "./Components/CenteredContainer";
import TopMenu from "./Components/TopMenu";
import Footer from "./Components/Footer";
import Button from "./Components/Button";

class HomeContact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			email: "",
			subject: "",
			body: "",
			done: false,
		};
	}
	changeField(event, field) {
		let update = {};
		update[field] = event.target.value;
		this.setState(update);
	}
	checkAll() {
		return (
			Util.check(this.state.name) ||
			Util.checkEmail(this.state.email) ||
			Util.check(this.state.subject) ||
			Util.check(this.state.body)
		);
	}
	send() {
		let data = {
			name: this.state.name,
			email: this.state.email,
			subject: this.state.subject,
			body: this.state.body,
		};
		new Api().fetch("sendMail", data, result => {
			//			console.log(result);
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.setState({
				name: "",
				email: "",
				subject: "",
				body: "",
				done: true,
			});
		});
	}
	render() {
		return (
			<CenteredContainer>
				<TopMenu App={this.props.App} />
				<section id="home-contact" className="screen no-margin">
					<div className="containt">
						<div className="row bg-primary">
							<div className="col-12 pt-sm-block text-center">
								<h1 className="mb-4">
									{Util.loc("Nous contacter")}
								</h1>
							</div>
						</div>
						<div className="row mt-5 px-5 px-md-0">
							<div
								className="col-12 col-sm-10 col-md-8 col-lg-6 contact block mx-auto needs-validation"
								noValidate
							>
								{this.state.done ? (
									<div className="col-12 text-center mb-30">
										<div className="row mb-30">
											<span className="col-12 text-primary text-medium">
												{Util.loc(
													"Votre message a bien été envoyé"
												)}
											</span>
										</div>
										<Link
											to={Util.HOME}
											className="btn btn-primary mb-0 mt-5"
											disabled
											type="button"
										>
											{Util.loc("Merci !")}
										</Link>
									</div>
								) : (
									<>
										<section className="px-1">
											<div className="form-row mt-3">
												<div className="w-100">
													<div className="row form-group">
														<div className="col-12 px-0">
															<input
																type="text"
																autoComplete={Util.random()}
																spellCheck={
																	false
																}
																className="form-control"
																id="name"
																placeholder={Util.loc(
																	"Mon nom *"
																)}
																required
																value={
																	this.state
																		.name
																}
																onKeyPress={event => {
																	if (
																		event.key ===
																		"Enter"
																	)
																		event.target.blur();
																}}
																onChange={event => {
																	this.changeField(
																		event,
																		"name"
																	);
																}}
															/>
															<label htmlFor="name">
																{Util.loc(
																	"Mon nom *"
																)}
															</label>
															<div
																style={Util.check(
																	this.state
																		.name,
																	true
																)}
																className="invalid-tooltip"
															>
																{Util.loc(
																	"Veuillez remplir ce champ correctement"
																)}
															</div>
														</div>
													</div>
													<div className="row form-group">
														<div className="col-12 px-0">
															<input
																type="text"
																autoComplete={Util.random()}
																spellCheck={
																	false
																}
																className="form-control"
																id="mail"
																placeholder={Util.loc(
																	"Mon email *"
																)}
																required
																value={
																	this.state
																		.email
																}
																onKeyPress={event => {
																	if (
																		event.key ===
																		"Enter"
																	)
																		event.target.blur();
																}}
																onChange={event => {
																	this.changeField(
																		event,
																		"email"
																	);
																}}
															/>
															<label htmlFor="mail">
																{Util.loc(
																	"Mon email *"
																)}
															</label>
															<div
																style={Util.checkEmail(
																	this.state
																		.email,
																	true
																)}
																className="invalid-tooltip"
															>
																{Util.loc(
																	"Veuillez remplir ce champ correctement"
																)}
															</div>
														</div>
													</div>
													<div className="row form-group">
														<div className="col-12 px-0">
															<input
																type="text"
																autoComplete={Util.random()}
																spellCheck={
																	false
																}
																className="form-control"
																id="object"
																placeholder={Util.loc(
																	"L'objet de ma demande *"
																)}
																required
																value={
																	this.state
																		.subject
																}
																onKeyPress={event => {
																	if (
																		event.key ===
																		"Enter"
																	)
																		event.target.blur();
																}}
																onChange={event => {
																	this.changeField(
																		event,
																		"subject"
																	);
																}}
															/>
															<label htmlFor="object">
																{Util.loc(
																	"L'objet de ma demande *"
																)}
															</label>
															<div
																style={Util.check(
																	this.state
																		.subject,
																	true
																)}
																className="invalid-tooltip"
															>
																{Util.loc(
																	"Veuillez remplir ce champ correctement"
																)}
															</div>
														</div>
													</div>
													<div className="row form-group">
														<div className="col-12 px-0">
															<textarea
																className="form-control"
																id="message"
																placeholder={Util.loc(
																	"Message *"
																)}
																rows="3"
																required
																value={
																	this.state
																		.body
																}
																onChange={event => {
																	this.changeField(
																		event,
																		"body"
																	);
																}}
															/>
															<label htmlFor="message">
																{Util.loc(
																	"Message *"
																)}
															</label>
															<div
																style={Util.check(
																	this.state
																		.body,
																	true
																)}
																className="invalid-tooltip"
															>
																{Util.loc(
																	"Veuillez remplir ce champ correctement"
																)}
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										<div className="col-12 text-center my-4">
											<Button
												onClick={() => this.send()}
												enabled={
													this.checkAll() ===
													undefined
												}
												style={{ width: "160px" }}
											>
												{Util.loc("Envoyer")}
											</Button>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</CenteredContainer>
		);
	}
}
export default HomeContact;
