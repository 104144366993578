import React, { Component } from "react";

//import { listProducts } from "../lib/productsHome";
import Util from "../lib/Util";
class HomeProducts extends Component {
	render() {
		var innerWidth = window.innerWidth;
		var imgWidth = innerWidth > 1440 ? 315 : innerWidth > 1125 ? 252 : 206;

		var nbImages = innerWidth > 940 ? 4 : innerWidth > 668 ? 3 : 2;

		if (innerWidth > 1820) innerWidth *= 0.75;
		var marginH =
			Math.floor((innerWidth - nbImages * imgWidth) / (nbImages * 2)) - 5;

		var rest =
			innerWidth - (marginH * (nbImages * 2) + imgWidth * nbImages);

		let listProducts = this.props.App.listProducts();
		//		console.log(JSON.stringify(listProducts));

		let isRowCompleted = listProducts.length % nbImages === 0;
		if (false)
			console.log(
				"render products",
				window.innerWidth,
				innerWidth,
				imgWidth,
				nbImages,
				marginH,
				rest,
				listProducts.length,
				isRowCompleted
			);
		return (
			<section
				id="home-products"
				className="row d-flex flex-row justify-content-between"
			>
				<div className="col-12 information-container py-md-5 py-lg-0">
					<div className="row d-flex justify-content-center">
						<div className="textual-container d-flex flex-column align-items-center">
							<span className="subtitle d-block">
								{Util.loc("SPOORS")}
							</span>
						</div>
					</div>
					<div className="mt-4 mb-5">
						<div className="text-center div col-12">
							<p className=" subsubtitle">
								{Util.loc(
									"Je sélectionne le produit le plus adapté à mes besoins\u00a0!"
								)}
							</p>
						</div>
					</div>
				</div>

				<div className="list-product">
					{listProducts.map((elem, elemIndex) => {
						return (
							<div
								className="list-product--item"
								id={"item" + elemIndex}
								key={elemIndex}
								style={{
									margin: "10px " + marginH + "px",
								}}
							>
								<img
									id="product-img"
									src={listProducts[elemIndex].image}
									alt=""
								/>

								<div className="product-block">
									<span className="product-name">
										{listProducts[elemIndex].nom}
									</span>
									<span className="product-desc">
										{listProducts[elemIndex].description}
									</span>
									<span className="product-price">
										{listProducts[elemIndex].prix}
									</span>

									<a
										href={listProducts[elemIndex].lien}
										target="_blank"
										className="product-link"
									>
										{Util.loc("Je découvre")}
									</a>
								</div>
							</div>
						);
					})}
					{innerWidth > 500 && (
						<div
							className="list-product--more"
							style={{
								margin: "10px " + marginH + "px",
							}}
						>
							<div
								className={
									isRowCompleted
										? "more-block-full"
										: "more-block-item text-center"
								}
								style={{
									width:
										isRowCompleted &&
										innerWidth - marginH * 2 - rest + "px",
								}}
							>
								<span className="more-title">
									{Util.loc("Découvrez bien plus\u00a0!")}
								</span>
								<a
									href="https://www.amazon.fr/stores/page/AC67A204-56B4-4C8A-BECC-710DF040FBF8?ingress=0&visitId=f2e3cada-2f14-431e-a499-4c56ad690a0c&ref_=ast_bln"
									target="_blank"
								>
									{Util.loc("Voir +")}
								</a>
							</div>
						</div>
					)}
				</div>
			</section>
		);
	}
}

export default HomeProducts;
