import React from "react";
import ReactDOM from "react-dom";
import GoogleAnalytics from "./lib/GoogleAnalytics";

// import './index.css';
import App from "./App";
//import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./style/css/style.css";
import "./style/css/bootstrap.css";

export const customHistory = createBrowserHistory();

//const unlisten =
customHistory.listen((location, action) => {
	// location is an object like window.location
	//	console.log(action, location.pathname, location.state);
	window.scrollTo(0, 0);

	//GoogleAnalytics watcher
	GoogleAnalytics.getInstance().sendPageView(
		`${location.pathname}${location.search}`
	);
});

ReactDOM.render(
	<Router history={customHistory}>
		<App history={customHistory} />
	</Router>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
