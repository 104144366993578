import React from "react";
import PropTypes from "prop-types";

const CenteredContainer = ({ id, className, children }) => (
	<div
		id={id ? id : ""}
		className={`screen-container ${className ? className : ""}`}
	>
		<div className="non-fluid-container">{children}</div>
	</div>
);

CenteredContainer.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
};

export default CenteredContainer;
