import React from "react";

import "bootstrap/js/src/tooltip";
import Util from "../lib/Util";

import Form from "./Form";

class FormAddress extends React.Component {
	constructor(props) {
		super(props);

		let defaultAddress = {
			address_type: Util.ADDRESS_PRIMARY,
			street: "",
			town: "",
			zip: "",
			country: "",
			address_complement: "",
			ok: true,
		};
		if (props.purpose) {
			defaultAddress = { [props.purpose]: defaultAddress };
			this.state = {
				...this.props.App.storyGet(defaultAddress)[props.purpose],
			};
		} else this.state = { ...this.props.App.storyGet(defaultAddress) };
		let q = this.state;
		this.previousAddr = q.street + " " + q.zip + " " + q.town;
		this.state.tmpAddr = this.previousAddr;
		this.state.auto = [];
		this.enter = false;
		/*		
				if (this.state.street !== "") {
					this.props.App.storyUpdate({ tmpAddr: this.state.street });
					this.state.tmpAddr = this.state.street;
					if (this.props.form) this.props.form.notify();
				}
			*/
	}
	storyUpdate(update) {
		if (this.props.purpose) {
			let address = this.props.App.storyGet()[this.props.purpose];
			for (var key in update) {
				address[key] = update[key];
			}
			update = { [this.props.purpose]: address };
		}
		this.props.App.storyUpdate(update);
	}
	onEnter() {
		this.enter = true;
	}
	onLeave() {
		this.enter = false;
		this.setState({ auto: [] });
	}
	onBlur(event) {
		if (this.enter) {
			this.enter = false;
			return;
		}
		this.setState({ auto: [], tmpAddr: this.previousAddr, ok: true });
	}
	onFocus() {
		this.getAuto(this.state.tmpAddr);
	}
	getAuto(value) {
		//		console.log("getAuto",JSON.stringify(this.state));
		this.current = value;
		if (value.length < 5) this.setState({ auto: [] });
		else
			setTimeout(() => {
				if (value !== this.current) return;

				let query = value.trim();
				var url =
					"https://api-adresse.data.gouv.fr/search/?limit=20&q=" +
					encodeURIComponent(query);
				console.log(url);
				fetch(url, {
					method: "get",
				})
					.then(function (response) {
						return response.json();
					})
					.then(
						result => {
							let auto = [];
							//					console.log(JSON.stringify(result.features));
							if (result.features)
								for (let p of result.features) {
									let q = p.properties;
									if (q.type !== "municipality") {
										let street = q.street || q.name;
										if (q.housenumber)
											street =
												q.housenumber + " " + street;
										auto.push({
											label:
												street +
												" " +
												q.postcode +
												" " +
												q.city,
											street: street,
											zip: q.postcode,
											town: q.city,
											country: "France",
										});
									}
								}
							this.setState({ auto: auto.slice(0, 10) });
						},
						error => {
							console.log(error);
							this.setState({ auto: [] });
						}
					);
			}, 500);
	}
	onChangeAddress(value) {
		value = Util.nice(value, "tmpAddr");
		this.setState({ tmpAddr: value, ok: false });
		this.getAuto(value);
	}
	onChange(value, field, process) {
		value = Util.nice(value, field);
		//		console.log("onChange", field, value);
		let update = { [field]: value };
		this.setState(update);
		this.storyUpdate(update);
		if (this.props.form) this.props.form.notify(process);
	}
	selectAuto(a) {
		//		console.log("selectAuto", a.label);
		let update = {
			street: a.street,
			zip: a.zip,
			town: a.town,
			country: a.country,
			ok: true,
		};

		//		console.log(update);
		this.setState(update);
		this.storyUpdate(update);
		this.previousAddr = a.label;
		this.setState({ auto: [], tmpAddr: this.previousAddr });
		if (this.props.form) this.props.form.notify(true);
	}
	render() {
		let id = Util.random();
		let radioName = "address_type-" + this.props.purpose;
		return (
			<div className="w-100">
				<div className="row form-category-title mb-3">
					<div className="col-12 px-0">
						<input
							type="text"
							autoComplete={Util.random()}
							spellCheck={false}
							className="form-profil-information"
							id={id}
							placeholder={""}
							required
							value={this.state.tmpAddr}
							onKeyPress={event => {
								if (event.key === "Enter") event.target.blur();
							}}
							onChange={event => {
								this.onChangeAddress(event.target.value);
							}}
							onBlur={event => {
								this.onBlur(event);
							}}
							onFocus={event => {
								this.onFocus();
							}}
						/>
						<label htmlFor={id}>{Util.loc("Adresse *")}</label>

						{this.state.auto.length ? (
							<div
								className="custom-select"
								style={{ borderStyle: "none" }}
							>
								<div
									className="dropdown-menu select show"
									onMouseEnter={() => this.onEnter()}
									onMouseLeave={() => this.onLeave()}
								>
									{this.state.auto.map((a, i) => {
										return (
											<a
												key={i}
												className="dropdown-item"
												onClick={() =>
													this.selectAuto(a)
												}
												style={{
													textOverflow: "ellipsis",
													whiteSpace: "nowrap",
													overflow: "hidden",
												}}
											>
												{a.label}
											</a>
										);
									})}
								</div>
							</div>
						) : (
							<div
								style={Util.check(
									this.state.ok
										? this.state.tmpAddr
										: undefined,
									this.props.withNext
								)}
								className="invalid-tooltip"
							>
								{Util.loc(
									"Veuillez remplir ce champ correctement"
								)}
							</div>
						)}
					</div>
				</div>
				<div className="row">
					<div className="col-12 mt-2 px-0">
						<input
							type="text"
							autoComplete={Util.random()}
							spellCheck={false}
							className="form-profil-information"
							id="address_complement"
							placeholder={Util.loc("Complément d'adresse")}
							value={this.state.address_complement}
							onKeyPress={event => {
								if (event.key === "Enter") event.target.blur();
							}}
							onChange={event => {
								this.onChange(
									event.target.value,
									"address_complement",
									false
								);
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}
export default FormAddress;
