import React from "react";
import Util from "../lib/Util";
import "../../node_modules/bootstrap/js/src/carousel";
import IcoTrash from "../style/img/trash.png";
import IcoPlus from "../style/img/ei-plus.png";

class DisplayList extends React.Component {
	constructor(props) {
		super(props);
		this.count = 0;
		this.phone = this.props.field === "phones";

		let update = {};
		update[this.props.field] = [];
		let story = this.props.App.storyGet(update);
		this.state = {
			list: JSON.parse(JSON.stringify(story[this.props.field])),
		};
	}

	render() {
		return (
			<React.Fragment>
				{this.state.list.length ? null : (
					<div
						className="profil-information"
						style={{
							font: "italic 20px roboto-bold",
							color: "#BDBDBD",
						}}
					>
						{this.phone
							? Util.loc("Pas de numéro de téléphone enregistré")
							: Util.loc("Pas d'email enregistré")}
					</div>
				)}
				{this.state.list.map((data, subindex) => {
					return (
						<div className="row" key={subindex}>
							<div className="col-12 px-0">
								<label className="category-title">
									{this.phone
										? Util.loc("TEL")
										: Util.loc("EMAIL")}
								</label>
								<label className="profil-information">
									{data}
								</label>
							</div>
						</div>
					);
				})}
			</React.Fragment>
		);
	}
}
export default DisplayList;
