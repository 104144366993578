import React from "react";
import Proptypes from "prop-types";

import Form from "./Form";
import Util from "../lib/Util";

const FormName = ({ className, onChange, value, title, ...props }) => (
	<div className={className}>
		<Form /* label={Util.loc("JE LE NOMME")} */>
			<p className="etape text-left">{title}</p>
			<div className="form-row">
				<div className="w-100 d-flex justify-content-center">
					<div className="row form-group w-100">
						<div className="col-12 px-0">
							<input
								type="text"
								spellCheck={false}
								className="form-control formControlStep "
								id="title"
								placeholder={""}
								required
								value={value}
								onKeyPress={event => {
									if (event.key === "Enter")
										event.target.blur();
								}}
								onChange={onChange}
								{...props}
							/>
							<label htmlFor="title">
								{value === ""
									? Util.loc("Nom du SPOORS *")
									: ""}
							</label>
							<div
								style={Util.check(value)}
								className="invalid-tooltip"
							>
								{Util.loc(
									"Veuillez remplir ce champ correctement"
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Form>
	</div>
);

FormName.propTypes = {
	onChange: Proptypes.func.isRequired,
	value: Proptypes.string.isRequired,
};

export default FormName;
