import React from "react";
import Util from "../lib/Util";

const HomeFunctioning = () => (
	<section id="home-functioning">
		<div className="row d-flex flex-row justify-content-center">
			<div className="col-12 col-md-8">
				<div className="row title-container d-flex flex-column justify-content-start align-items-center align-items-md-end p-0">
					<div className="col-12 title text-center text-md-right">
						{Util.loc("Fonctionnement")}
					</div>
					<div className="col-12 subtitle text-center text-md-right d-block pr-lg-4">
						SPOORS
					</div>
				</div>
			</div>
		</div>
		<div className="steps-container">
			<div className="row row-wth-img d-flex flex-row justify-content-center pt-5">
				<div className=" col-4 phone-spoors-container px-0" />
				<div className="col-7 col-md-6">
					<div className="row">
						<div className="col-12 step title-step-1 text-left">
							{Util.loc("1. Une personne scanne mon QR code")}
						</div>
						<div className="col-12 body-2 mt-3 mt-md-0 text-left">
							{Util.loc(
								"Mon interlocuteur scanne le code avec son appareil photo ou un lecteur de QR code. Il peut me contacter ou demander à être rappelé sans avoir à charger d’application."
							)}
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-2">
							<div className="arrow-container mt-5 pl-3" />
						</div>
					</div>
				</div>
			</div>
			<div className="row row-wth-img d-flex flex-row justify-content-center pt-5">
				<div className="col-7 col-md-6">
					<div className="row d-flex flex-column align-items-end">
						<div className="col-12 col-md-10 col-lg-12 step text-right">
							{Util.loc(
								"2. Réception de l’appel sur mon mobile\n"
							)}
						</div>
						<div className="col-12 col-md-11 body-2 text-right mt-3 mt-md-0 pt-md-4 pr-3 pr-md-5">
							{Util.loc(
								"Grâce à la technologie brevetée SPOORS, je suis mis en relation sans que mes coordonnées ne soient dévoilées."
							)}
						</div>
					</div>
				</div>
				<div className="col-4 col-md-6 phone-spoors-2-container" />
			</div>
		</div>
	</section>
);

export default HomeFunctioning;
