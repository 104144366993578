import React from "react";
import PropTypes from "prop-types";

const BlueContainer = props => (
	<section
		id="blue-container"
		className="d-flex justify-content-between flex-column py-5"
	>
		<div className="row">
			<div className="col-12 px-5 title montserrat-bold">
				{props.title}
			</div>
		</div>
		<div className="row d-flex flex-row justify-content-center">
			<div
				className="col-sm-12 col-md-10 col-lg-4	body-1"
				dangerouslySetInnerHTML={{
					__html: props.text,
				}}
			/>
		</div>
		<div className="row">
			<div className="col-12">
				<button>
					{props.btnAction ? (
						<a
							style={{ color: "#fff" }}
							onClick={() => props.btnAction()}
						>
							{props.btnText}
						</a>
					) : (
						<a
							style={{ color: "#fff" }}
							href={props.href}
							target="_blank"
						>
							{props.btnText}
						</a>
					)}
				</button>
			</div>
		</div>
	</section>
);

BlueContainer.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	btnText: PropTypes.string.isRequired,
};

export default BlueContainer;
