import React from "react";
import { Helmet } from "react-helmet";

import headerImg1 from "./style/img/headerImg1_v2.jpg";
import headerImg1Mobil from "./style/img/headerImg1_mobil.jpg";
import headerImg2 from "./style/img/headerImg4.jpg";
import headerImg2Mobil from "./style/img/headerImg4_mobil.jpg";
import headerImg3 from "./style/img/headerImg5.jpg";
import headerImg3Mobil from "./style/img/headerImg5_mobil.jpg";

import HomeTopMenu from "./Components/HomeTopMenu";
import CenteredContainer from "./Components/CenteredContainer";
import HomeDescription from "./Components/HomeDescription";
import HomeProducts from "./Components/HomeProducts";
import HomeAssets from "./Components/HomeAssets";
import HomeFunctioning from "./Components/HomeFunctioning";
import HomeInstallation from "./Components/HomeInstallation";
import HomeBottomPage from "./Components/HomeBottomPage";
import Carousel from "./Components/Carousel";

import "./style/css/bootstrap.css";

import Util from "./lib/Util";

class Home extends React.Component {
	setRefForScroll(elem) {
		if (!elem) return;
		//		console.log("setRef", elem.id);
		if (elem.id === this.props.section) {
			//console.log(this.props.section);
			elem.scrollIntoView();
		}
	}
	makeImg(img, width, height) {
		return { img, width, height };
	}
	render() {
		const { App } = this.props;

		let carouselPictures =
			window.innerWidth > 500
				? [
						this.makeImg(headerImg1, 1442, 490),
						this.makeImg(headerImg2, 1442, 490),
						//						this.makeImg(headerImg3, 1442, 490),
				  ]
				: [
						this.makeImg(headerImg1Mobil, 528, 465),
						this.makeImg(headerImg2Mobil, 528, 465),
						//						this.makeImg(headerImg3Mobil, 528, 465),
				  ];

		return (
			<>
				<Helmet>
					<title>
						{Util.loc(
							"SPOORS : toujours joignable sans dévoiler mes coordonnées"
						)}
					</title>
					<meta
						name="description"
						content={Util.loc(
							"Toujours joignable sans divulguer mes coordonnées ! la Sonnette pour être prévenu en cas de dégât des eaux ; la Vignette pour être alerté si mon véhicule gêne la circulation.\n"
						)}
					/>
				</Helmet>
				<CenteredContainer className="home">
					<HomeTopMenu App={App} className="fixed" />
					<Carousel pictures={carouselPictures} />
					<HomeDescription />
					<div
						id="products"
						style={{ position: "relative", top: -50 }}
						ref={elem => this.setRefForScroll(elem)}
					/>
					<HomeProducts App={App} />
					<div id="asset" ref={elem => this.setRefForScroll(elem)} />
					<HomeAssets />
					<div
						id="functioning"
						style={{ position: "relative", top: -50 }}
						ref={elem => this.setRefForScroll(elem)}
					/>
					<div className="row">
						<div className="col-sm-12 col-lg-6 p-0">
							<HomeFunctioning />
						</div>
						<div className="col-sm-12 p-0 col-lg-6">
							<HomeInstallation />
						</div>
					</div>
					<div id="footer" ref={elem => this.setRefForScroll(elem)}>
						<HomeBottomPage />
					</div>
				</CenteredContainer>
			</>
		);
	}
}
export default Home;
