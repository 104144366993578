import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import Api from "./lib/Api.js";

import CenteredContainer from "./Components/CenteredContainer";
import TopMenu from "./Components/TopMenu";
import IcoPlus from "./style/img/icon-plus.svg";
import BottomPage from "./Components/BottomPage";

class Contacts extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contacts: [],
			edit: false,
		};
		this.textInput = null;
		this.setTextInputRef = element => {
			this.textInput = element;
		};
		this.props.App.fetchMe(() => {
			this.updateList();
		});
	}
	updateList() {
		let contacts = this.props.App.getContacts(false);
		this.setState({ contacts: contacts });
	}
	select(elem) {
		console.log("select " + elem.id);
		let data = { id: elem.id };
		new Api().fetch("contactEdit", data, result => {
			//			console.log(result);
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.props.App.storySet(result.story);
			this.props.App.go(Util.CONTACT_EDIT);
		});
	}
	add() {
		this.setState({ edit: "" });
		setTimeout(() => {
			if (this.textInput) this.textInput.focus();
		}, 200);
	}
	onTrash() {
		this.setState({ edit: false });
	}
	onBlur() {
		if (this.check()) return this.setState({ edit: false });
		this.props.App.contactNew(this.state.edit, () => {
			this.updateList();
			this.setState({ edit: false });
		});
	}
	onChange(value) {
		value = Util.nice(value, "label");
		this.setState({ edit: value });
	}
	check() {
		let value = this.state.edit;
		if (value === "") return { display: "block" };
		for (let elem of this.state.contacts)
			if (elem.label === value) return { display: "block" };
		if (this.props.App.firstname() === value) return { display: "block" };
	}
	render() {
		const { App } = this.props;

		return (
			<CenteredContainer id="page" className="screen-container">
				<div id="contacts" className="claimBackground">
					<TopMenu App={App} />
					<div className="row d-flex flex-row justify-content-center inputTop">
						<div className="col-12 col-md-10 col-lg-6 ">
							<div className="d-flex align-items-center justify-content-between">
								<label className="form-control">
									{Util.loc("JE GÈRE MES CONTACTS")}
								</label>
							</div>
						</div>
					</div>

					<div className="row containt">
						<div
							className="col-12 col-sm-10 col-md-8 col-lg-6 block pt-block mx-auto needs-validation"
							noValidate
						>
							<section className="col-12 pb-block text-center w-100">
								{this.state.contacts.map((elem, index) => {
									return (
										<div
											className="btn-contact"
											key={index}
										>
											<div className="col-12">
												<div className="custom-control checkbox-block">
													<input
														type="checkbox"
														id={elem.id}
														className="custom-control-input"
														onChange={() =>
															this.select(elem)
														}
													/>
													<label
														className="custom-control-label"
														htmlFor={elem.id}
														style={{
															cursor: "pointer",
														}}
													>
														{elem.label}
													</label>
												</div>
											</div>
										</div>
									);
								})}

								{this.state.edit === false ? (
									<div className="col-12">
										<button
											className="contact-btn-add"
											onClick={() => this.add()}
										>
											<img
												className="img-add"
												src={IcoPlus}
												alt="more"
											/>
											<span className="align-middle contact-text-add">
												{Util.loc("Ajouter un contact")}
											</span>
										</button>
									</div>
								) : (
									<div className="col-12 mx-auto block-gestion-contact">
										<div className="row mt-30">
											<div className="col-12">
												<div className="custon-control checkbox-block">
													<input
														type="mail"
														autoComplete={Util.random()}
														spellCheck={false}
														className="form-control"
														required
														id="newContact"
														ref={
															this.setTextInputRef
														}
														value={this.state.edit}
														placeholder={Util.loc(
															"Nom du contact"
														)}
														onKeyPress={event => {
															if (
																event.key ===
																"Enter"
															)
																event.target.blur();
														}}
														onBlur={() => {
															this.onBlur();
														}}
														onChange={event => {
															this.onChange(
																event.target
																	.value
															);
														}}
													/>
													{true ? null : (
														<label htmlFor="newContact">
															{Util.loc(
																"Nom du nouveau contact"
															)}
														</label>
													)}
													<div
														style={this.check(
															this.state.edit
														)}
														className="invalid-tooltip"
													>
														{Util.loc(
															"Veuillez saisir le nom du nouveau contact"
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
							</section>
						</div>
					</div>
				</div>
				<BottomPage />
			</CenteredContainer>
		);
	}
}

Contacts.propTypes = {
	App: Proptypes.object.isRequired,
};

export default Contacts;
