import React from "react";
import { Link } from "react-router-dom";
import Util from "./lib/Util";
import TopMenu from "./Components/TopMenu";
import Footer from "./Components/Footer";

class HomeError extends React.Component {
	constructor(props) {
		super(props);
		this.count = 0;
	}
	render() {
		let error = this.props.App.errorGet();
		if (!error)
			error = {
				title: "foobar",
				msg: "Hello world",
				buttons: [
					{ name: "Cancel", route: Util.HOME },
					{ name: "Ok", route: Util.HOME },
				],
			};
		return (
			<React.Fragment>
				<TopMenu App={this.props.App}></TopMenu>
				<div className="row">
					<h3>{error.title}</h3>
				</div>
				<div className="row">{error.msg}</div>
				<div className="row">
					{error.buttons.map((elem, index) => {
						return (
							<div className="col-1" key={this.count++}>
								<Link to={elem.route}>
									<button className="btn btn-primary">
										{elem.name}
									</button>
								</Link>
							</div>
						);
					})}
				</div>
				<Footer></Footer>
			</React.Fragment>
		);
	}
}
export default HomeError;
