import React from "react";
import PropTypes from "prop-types";

import BoxNumber from "./BoxNumber";

const HomeInstallationStep = props => (
	<div className="row home-installation-step d-flex flex-row justify-content-center">
		<div className="col-2 p-0 d-flex flex-column justify-content-start align-items-center mt-2">
			<BoxNumber number={props.stepNumber} />
		</div>
		<div className="col-8">
			<div className="row">
				<div className="col-12 title text-left">{props.title}</div>
			</div>
			<div className="row">
				<div className="col-12">{props.children}</div>
			</div>
		</div>
	</div>
);

HomeInstallationStep.propTypes = {
	stepNumber: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default HomeInstallationStep;
