import React from "react";
import PropTypes from "prop-types";

const AssetCard = props => (
	<div className={`home-assets-card asset-card py-5 ${props.className}`}>
		<div className="row">
			<div
				className="col-12 image-container"
				style={{ backgroundImage: `url(${props.image})` }}
			/>
		</div>
		<div className="row title pt-5 pb-3">
			<div
				className="col-12"
				dangerouslySetInnerHTML={{ __html: `${props.title}` }}
			/>
		</div>
		<div className="row">
			<div
				className="col-12 body-2"
				dangerouslySetInnerHTML={{ __html: `${props.text}` }}
			/>
		</div>
	</div>
);

AssetCard.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	image: PropTypes.string.isRequired,
};

export default AssetCard;
