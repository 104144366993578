import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";

import IcoLink from "./style/img/ico-link-2.png";

import Page from "./Components/Page";
import ButtonSuivant from "./Components/ButtonSuivant";

class Claim extends React.Component {
	constructor(props) {
		super(props);
		props.App.storyNeed("/CLAIM", Util.HOME);

		this.story = props.App.storyGet();
		this.state = {
			checkOn: 1,
		};
		if (!this.story.user) {
			return this.props.App.go(Util.CLAIM_USAGE);
		}
		props.App.storyUpdate({ popupDone: true });
	}

	goNext = () => {
		if (this.state.checkOn === 1)
			return this.props.App.go(Util.CLAIM_USAGE);
		this.props.App.logout(() => {
			this.props.App.go(Util.BELL + "/" + this.story.bell);
		});
	};

	// nicolasC
	checkBtn = val => {
		this.setState({ checkOn: val });
	};

	render() {
		const { App } = this.props;
		let admin = this.props.App.isAdmin();

		return (
			<Page
				App={App}
				title={Util.loc("Rattachement")}
				stepClass="title-containerEtape "
			>
				<div className="row position-relative containt claimBackground">
					<div className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto text-center block">
						<div className="ico-title-no-bg m-auto">
							<img src={IcoLink} alt="link" />
						</div>
						<div className="row">
							<div className="m-auto col-12">
								<div className="col-12 mb-4 color-blue600">
									<p className="info-title">
										{Util.loc(
											"Je veux rattacher ce SPOORS au compte\u00a0:"
										)}
									</p>
									<div className="claim-bold">
										<p>{this.story.email}</p>
									</div>
								</div>
								<div className="col-9 mx-auto">
									{/* nicolasC */}
									<div className="d-flex justify-content-around ">
										<div
											className={`btn-rattachement ${
												this.state.checkOn === 1
													? "btn-rattachement-check"
													: ""
											}`}
											onClick={() => {
												this.checkBtn(1);
											}}
										>
											<p>{Util.loc("Oui")}</p>
										</div>
										<div
											className={`btn-rattachement ${
												this.state.checkOn === 2
													? "btn-rattachement-check"
													: ""
											}`}
											onClick={() => {
												this.checkBtn(2);
											}}
										>
											<p>{Util.loc("non")}</p>
										</div>
									</div>
									{/* nicolasC FIN */}
								</div>
								<div className="row mt-5 mb-5">
									<div className="col-10 m-auto">
										<div className="btnRevenirSuivant">
											<ButtonSuivant
												onClick={this.goNext}
												enabled
												className="step"
											>
												{Util.loc("Suivant")}
											</ButtonSuivant>
										</div>
									</div>
								</div>
								{admin ? (
									<a
										href={
											Util.URL +
											"admin.php#/bell/" +
											encodeURIComponent(
												JSON.stringify({
													code: this.story.bell,
												})
											)
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										π
									</a>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</Page>
		);
	}
}

Claim.propTypes = {
	App: Proptypes.object.isRequired,
};

export default Claim;
