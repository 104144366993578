import React from "react";
import Util from "../lib/Util";
import Call from "../lib/VoxImplant";

// img
import Tel from "../style/img/phone-white.png";
import HangUp from "../style/img/icon-phone.svg";

class Phone extends React.Component {
	constructor(props) {
		super(props);
		let story = this.props.App.storyGet();
		this.state = {
			current: story.ring,
			calling: false,
			connected: false,
			first: true,
			delay: 0,
		};
		this.t0 = 0;
	}
	trynext() {
		this.t0 = 0;
		let story = this.props.App.storyGet();
		this.setState({ current: story.ring, first: false });
	}
	timer() {
		setTimeout(() => {
			if (this.t0 === 0) return;
			let delay = Math.round((Util.timeMs() - this.t0) / 1000);
			this.setState({ delay: delay });
			console.log("delay=", delay);
			if (delay >= Util.CALL_TIMEOUT) {
				this.t0 = 0;
				return this.hangup();
			}
			this.timer();
		}, 1000);
	}
	callstart() {
		if (
			navigator &&
			navigator.mediaDevices &&
			navigator.mediaDevices.getUserMedia
		) {
			console.log("getUserMedia");
			navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then(stream => {
					console.log("You let me use your mic!");
					this.callstart2();
				})
				.catch(err => {
					console.log("No mic for you!");
					this.props.App.alert(
						Util.loc("Microphone inaccessible"),
						Util.loc(
							"Veuillez autoriser l'utilisation du microphone pour le site spoors.fr!"
						)
					);
				});
		} else this.callstart2();
	}
	callstart2() {
		if (this.state.calling) return;
		this.setState({ calling: true, delay: 0 });
		this.props.App.storyProcess(result => {
			let story = this.props.App.storyGet();
			if (!this.state.calling) return;
			Call.callstart(
				story.voxuser,
				story.voxpwd,
				story.token,
				(code, e) => {
					console.log("Call cb :", code);
					if (code === Call.OK_CONNECTED) {
						this.setState({
							connected: true,
							delay: 0,
						});
						this.t0 = Util.timeMs();
						this.timer();
						return;
					}
					if (code === Call.OK_DISCONNECTED) {
						// fin d'appel "propre"
						this.setState({ connected: false, calling: false });
						return this.trynext();
					}
					if (code === Call.ERR_FAILED) {
						// erreur de l'appel
						this.setState({ connected: false, calling: false });
						this.props.App.alert(
							e.name + " #" + e.code,
							Util.loc("Échec de l'appel !")
						);
						return this.trynext();
					}
					if (code === Call.ERR_CONNECT) {
						// erreur de connexion du sdk
						console.log(e);
						this.setState({ connected: false, calling: false });
						if (e.name && e.name === "MicAccessResult") {
							this.props.App.alert(
								Util.loc("Microphone inaccessible"),
								Util.loc(
									"Veuillez autoriser l'utilisation du microphone pour le site spoors.fr!"
								)
							);
							return;
						}
						if (e.name && e.name === "NetworkUnreachable") {
							this.props.App.alert(
								Util.loc("Serveur inaccessible"),
								Util.loc(
									"Connexion impossible ! Assurez-vous que vous n'êtes pas bloqué par un firewall d'entreprise"
								)
							);
							return;
						}
						this.props.App.alert(
							e.name + " #" + e.code,
							Util.loc("Échec de l'appel !")
						);
						return this.trynext();
					}
				}
			);
		});
	}
	hangup() {
		Call.hangup();
		this.setState({ connected: false, calling: false });
	}

	render() {
		if (this.state.current < 0) return null; // pas d'autres numéros à appeler composant invisible
		//		console.log('vox', this.state.current);
		return (
			<React.Fragment>
				<div className="row">
					<div className="col-10 m-auto d-flex justify-content-center">
						{this.state.calling ? (
							<>
								{/*  */}
								{/*  */}
								<div
									id="modal-call"
									className="modal-call-customize"
								>
									<div className="delay-call">
										<p className="bold">
											{Util.loc("Appel en cours")}
										</p>

										{this.state.connected ? (
											<p>{Util.loc("Connecté :")}</p>
										) : (
											<>
												<p>
													{Util.loc(
														"En recherche de votre correspondant..."
													)}
												</p>
												<p>
													{Util.loc(
														"Assurez-vous du volume et du micro"
													)}
												</p>
											</>
										)}

										<p className="big-number mt-4">
											{this.state.delay} <span>s</span>
										</p>
									</div>
									<button onClick={() => this.hangup()}>
										<img
											src={HangUp}
											alt={Util.loc("Raccrocher")}
										/>
										{Util.loc("Raccrocher")}
									</button>
								</div>
								{/*  */}
								{/*  */}
								{/* <button
									className="btn btn-primary btn-block"
									onClick={() => this.hangup()}
								>
									{Util.loc("Raccrocher")}
								</button>
								<div className="row">
									<div className="col-12 m-auto">
										{this.state.connected
											? Util.fillStr(
													Util.loc(
														"Connecté : [delay] s"
													),
													{ delay: this.state.delay }
											  )
											: Util.loc(
													"En recherche de votre correspondant... Assurez-vous du volume et du micro"
											  )}
									</div>
								</div> */}
							</>
						) : (
							<button
								className="btn btn-primary btn-block"
								onClick={() => this.callstart()}
							>
								<img src={Tel} alt={Util.loc("Appeler")} />
								{this.state.first
									? Util.loc("Appeler")
									: Util.loc("Autre numéro")}
							</button>
						)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}
export default Phone;
