import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import Api from "./lib/Api.js";
import { Link } from "react-router-dom";
import FormList from "./Components/FormList";
import FormContactName from "./Components/FormContactName";

import CenteredContainer from "./Components/CenteredContainer";
import TopMenu from "./Components/TopMenu";
import BottomPage from "./Components/BottomPage";

import ArrowLeft from "../src/style/img/icon-arrow-left.svg";

class ContactEdit extends React.Component {
	constructor(props) {
		super(props);
		this.count = 0;
		this.props.App.storyNeed("/CONTACT_UPDATE", Util.CONTACTS);
		this.state = {
			label: this.props.App.storyGet().label,
		};
	}
	notify(readyToProcess) {
		if (readyToProcess) this.props.App.storyProcess();
	}

	onChange = value => {
		value = Util.nice(value, "label");
		this.setState({ label: value });
	};

	onBlur = () => {
		if (Util.checkLabel(this.state.label, this.props.App.firstname())) {
			this.setState({
				label: this.props.App.storyGet().label,
			});
			return;
		}
		this.props.App.storyUpdate({ label: this.state.label });
		this.notify(true);
	};
	onDelete = () => {
		this.props.App.confirmPopup(
			Util.loc(
				"Etes-vous sûr de vouloir supprimer ce contact\u00a0? Cela supprimera les alertes et les droits qui lui sont associés"
			),
			result => {
				if (!result) return;
				this.props.App.storyUpdate({
					phones: [],
					emails: [],
					remove: true,
				});
				this.props.App.storyProcess(result => {
					this.props.App.go(Util.CONTACTS);
				});
			}
		);
	};
	render() {
		const { App } = this.props;
		const { label } = this.state;

		return (
			<CenteredContainer id="page" className="screen-container">
				<div id="contactEdit" className="claimBackground">
					<TopMenu App={App} />
					<div className="row d-flex flex-row justify-content-center inputTop">
						<div className="col-12 col-md-10 col-lg-6 ">
							<div className="d-flex align-items-baseline justify-content-between padd20">
								<Link to={Util.CONTACTS}>
									<img src={ArrowLeft} />
								</Link>
								<label className="form-control">
									{Util.loc("JE GÈRE MES CONTACTS")}
								</label>
							</div>
						</div>
					</div>

					<div id="my-profile" className="row containt">
						<section className="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto block">
							<div className="text-center row">
								<div className="w-100">
									<div
										className="tab-content"
										id="pills-tabContent"
									>
										<div
											className="tab-pane fade active show"
											id="pills-info"
											role="tabpanel"
											aria-labelledby="pills-info-tab"
										>
											<div
												className="col-12 needs-validation"
												noValidate
											>
												<div className="text-left profil-info px-1">
													<FormContactName
														App={this.props.App}
														form={this}
													/>
													<FormList
														App={this.props.App}
														field={"phones"}
														form={this}
													/>
													<FormList
														App={this.props.App}
														field={"emails"}
														form={this}
													/>
													<div id="bell-edit">
														<div className="text-center">
															<button
																onClick={() =>
																	this.props.App.go(
																		Util.CONTACTS
																	)
																}
																className="btn btn-border btn-confirm"
															>
																{Util.loc(
																	"Valider"
																)}
															</button>
														</div>
														<div className="text-center">
															<button
																onClick={() =>
																	this.onDelete()
																}
																className="btn btn-border trash-border btn-del-contact"
															>
																{Util.fillStr(
																	Util.loc(
																		"Supprimer [nom]"
																	),
																	{
																		nom: this
																			.state
																			.label,
																	}
																)}
															</button>
														</div>
														<Link
															className="text-center mb-2"
															to={
																Util.CONTACT_ROLE
															}
														>
															<p className="gestion-droits">
																{Util.loc(
																	"Gestion des droits"
																)}
															</p>
														</Link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
				<BottomPage />
			</CenteredContainer>
		);
	}
}

ContactEdit.propTypes = {
	App: Proptypes.object.isRequired,
};

export default ContactEdit;
