import React from "react";
import Proptypes from "prop-types";

import Form from "./Form";
import Util from "../lib/Util";

const FormEmail = ({ onChange, value, ...props }) => (
	<Form label={Util.loc("Mon email")}>
		<div className="form-row">
			<div className="w-100">
				<div className="row form-group">
					<div className="col-12 px-0">
						<input
							type="text"
							spellCheck={false}
							className="form-control"
							id="email"
							placeholder={""}
							required
							value={value}
							onKeyPress={event => {
								if (event.key === "Enter") event.target.blur();
							}}
							onChange={onChange}
							{...props}
						/>
						<label htmlFor="email">{Util.loc("Email *")}</label>
						<div
							style={Util.checkEmail(value, true)}
							className="invalid-tooltip"
						>
							{Util.loc("Veuillez remplir ce champ correctement")}
						</div>
					</div>
				</div>
			</div>
		</div>
	</Form>
);

FormEmail.propTypes = {
	onChange: Proptypes.func.isRequired,
	value: Proptypes.string.isRequired,
};

export default FormEmail;
