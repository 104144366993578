import React from "react";
import Proptypes from "prop-types";

import Util from "../lib/Util";

class FormContactName extends React.Component {
	constructor(props) {
		super(props);
		this.count = 0;
		this.props.App.storyNeed("/CONTACT_UPDATE", Util.CONTACTS);
		this.state = {
			label: this.props.App.storyGet().label,
		};

		console.log("-------------------Formidentity");
		console.log(JSON.stringify(this.state));
	}
	onBlur(field) {
		let value = this.state[field].trim();
		this.onChange(value, field, true);
	}
	onChange(value, field, process) {
		value = Util.nice(value, field);
		let update = {};
		update[field] = value;
		this.setState(update);
		this.props.App.storyUpdate(update);
		if (this.props.form) this.props.form.notify(process);
	}
	render() {
		return (
			<div className="w-100">
				<div className="row form-category-title">
					<div className="col-12 px-0">
						<input
							type="text"
							autoComplete={Util.random()}
							spellCheck={false}
							className="form-profil-information"
							id="name"
							placeholder={""}
							required
							value={this.state.label}
							onKeyPress={event => {
								if (event.key === "Enter") event.target.blur();
							}}
							onChange={event => {
								this.onChange(event.target.value, "label");
							}}
							onBlur={event => {
								this.onBlur("label");
							}}
						/>
						<label htmlFor="name">{Util.loc("Nom *")}</label>
						<div
							style={Util.check(
								this.state.label,
								this.props.withNext
							)}
							className="invalid-tooltip"
						>
							{Util.loc("Veuillez remplir ce champ correctement")}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

FormContactName.propTypes = {
	App: Proptypes.object.isRequired,
	purchase: Proptypes.bool,
	form: Proptypes.object,
	withText: Proptypes.bool,
};

export default FormContactName;
