import React from "react";
import Proptypes from "prop-types";
import { Link } from "react-router-dom";

import ChevRetourIcon from "../style/img/chevron-retour.svg";

const ChevRetour = ({ id, classNames, to }) => (
	<Link id="step-back" className={`${classNames ?? ""}`} to={to}>
		<img src={ChevRetourIcon} alt="" />
	</Link>
);

ChevRetour.propTypes = {
	classNames: Proptypes.string,
	to: Proptypes.string.isRequired,
};

export default ChevRetour;
