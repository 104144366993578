import React from "react";
import Proptypes from "prop-types";

const BoxNumber = props => (
	<div className="step-number-box d-flex justify-content-center align-items-center">
		{props.number}
	</div>
);

BoxNumber.propTypes = {
	number: Proptypes.number.isRequired,
};

export default BoxNumber;
