import React, { Component } from "react";

import Util from "../lib/Util";

import BlueContainer from "./BlueContainer";
import Footer from "./Footer";

class HomeBottomPage extends Component {
	setRefForScroll(elem) {
		if (!elem) return;
		//		console.log("setRef", elem.id);
		if (elem.id === this.props.section) {
			//			console.log("FOUND!!");
			elem.scrollIntoView();
		}
	}

	render() {
		return (
			<>
				<div style={{ position: "relative", top: -50 }} />
				<Footer home={true} />
			</>
		);
	}
}

export default HomeBottomPage;
