import React from "react";
import IcoAlert from "../style/img/Illu_PopUp_x2.png";
import IcoValid from "../style/img/Popup_valid.png";

// gif animé
import Spoors from "../style/img/spinner_spoors_anim2.gif";

class Popup extends React.Component {
	/*	constructor(props) {
		super(props);
	}
*/
	done(result) {
		let cb = this.props.options.cb;
		this.props.App.popup();
		if (cb) cb(result);
	}
	render() {
		let options = this.props.options;
		let ico = options.icon === "ok" ? IcoValid : IcoAlert;
		console.log("popup render ");
		console.log(options);

		return (
			<div
				className="modal"
				id="modalCookies"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modalCookiesTitle"
				aria-hidden="false"
				style={{ display: "block" }}
			>
				<div
					className="modal-dialog modal-dialog-centered"
					role="document"
				>
					<div className="modal-content">
						<div className="modal-header position-relative">
							{options.close ? (
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={() => this.done(false)}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							) : null}
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-12 text-center img-spoors">
									<img src={Spoors} alt="" />
								</div>
								<div className="col-12 text-center">
									<p style={{ whiteSpace: "pre-wrap" }}>
										{options.text}
									</p>
								</div>
							</div>
						</div>
						{options.no ? (
							<div className="modal-footer px-0">
								<div className="w-100 d-flex m-0 justify-content-around">
									<button
										type="button"
										className="btn-rattachement"
										onClick={() =>
											this.done(options.no.value)
										}
									>
										{options.no.text}
									</button>
									{options.no.tooltip ? (
										<div className="info-refuse">
											{options.no.tooltip}
										</div>
									) : null}
									<button
										type="button"
										className="btn-rattachement"
										onClick={() =>
											this.done(options.yes.value)
										}
									>
										{options.yes.text}
									</button>
								</div>
							</div>
						) : options.yes ? (
							<div className="modal-footer px-0">
								<div className="w-100 d-flex m-0 justify-content-around">
									<button
										type="button"
										className="btn-rattachement"
										onClick={() =>
											this.done(options.yes.value)
										}
									>
										{options.yes.text}
									</button>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}
export default Popup;
