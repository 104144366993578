import React from "react";
import Util from "../lib/Util";

import { COUNTRIES } from "../lib/Countries";

class SelectCountry extends React.Component {
	render() {
		//console.log("country code : " + this.props.countryCode);
		return (
			<select
				style={{
					margin: this.props.margin,
					display: Util.INTERNATIONAL ? null : "none",
				}}
				className="form-select-countries"
				id="countries"
				value={this.props.countryCode}
				onChange={event =>
					this.props.onCountry
						? this.props.onCountry(event.target.value)
						: null
				}
			>
				{Object.entries(COUNTRIES())
					.sort(function (a, b) {
						if (a[1] > b[1]) {
							return 1;
						}
						if (a[1] < b[1]) {
							return -1;
						}
						return 0;
					})
					.map((country, i) => (
						<option key={i} value={country[0]}>
							{country[0] + " - " + country[1]}
						</option>
					))}
			</select>
		);
	}
}

export default SelectCountry;
