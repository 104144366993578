import React, { Component } from "react";
import Proptypes from "prop-types";

import DownArrow from "../style/img/down-arrow.png";

class UnitsSelector extends Component {
	constructor(props) {
		super(props);
	}

	renderSelectItems() {
		let unitsPriceDict = this.props.packs;
		return Object.entries(unitsPriceDict).map(([key, p]) => (
			<option key={key} value={key}>
				{key}
			</option>
		));
	}

	render() {
		return (
			<div className="units-selector">
				<select
					onChange={this.props.onValueChange}
					value={this.props.nbUnits}
				>
					{this.renderSelectItems()}
				</select>
				<img src={DownArrow} alt="downarrow" />
			</div>
		);
	}
}

UnitsSelector.propTypes = {
	onValueChange: Proptypes.func.isRequired,
	nbUnits: Proptypes.string.isRequired,
};

export default UnitsSelector;
