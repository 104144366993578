import { loadStripe } from "@stripe/stripe-js";

var StripeInstance;

function Stripe(kpub, cb) {
	if (StripeInstance) return cb(StripeInstance);
	loadStripe(kpub).then(stripe => {
		StripeInstance = stripe;
		cb(StripeInstance);
	});
}

export default Stripe;
