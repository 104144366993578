import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import PropTypes from "prop-types";

import SpoorsLogo from "../style/img/spoors-logo.png";
import MenuBurger from "../style/img/menu-burger.svg";
import QrCode from "../style/img/QrCode.svg";
import User from "../style/img/User.svg";

import { customHistory } from "../index";
import Util from "../lib/Util";
import SelectLanguage from "./SelectLanguage";

class HomeTopMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showMenu: false,
			showTopBar: window.innerWidth >= 570,
		};
		this.setTopBar = this.setTopBar.bind(this);
	}

	onBurgerBtnClick = () => {
		this.setState(prevState => ({ showMenu: !prevState.showMenu }));
	};

	onNavigationLinkClicked = () => {
		this.setState({ showMenu: false });
	};

	onSignOutClick = () => {
		this.setState({ showMenu: false });

		this.props.App.popup({
			text: Util.loc("Voulez-vous vraiment vous déconnecter ?"),
			close: false,
			no: {
				text: Util.loc("Non"),
				tooltip: false,
				value: false,
			},
			yes: {
				text: Util.loc("Oui"),
				value: true,
			},
			cb: result => {
				if (result) return this.props.App.logout();
			},
		});
	};

	renderMenu = () => {
		const { showMenu } = this.state;

		if (showMenu) {
			return (
				<div className="menu">
					<div className="menu-items">{this.renderMenuContent()}</div>
				</div>
			);
		}

		return null;
	};

	renderMenuContent = () => {
		let registered = this.props.App.isRegistered();
		let admin = this.props.App.isAdmin();

		let bellNb = this.props.App.getBellNb();

		let contents = [
			<Link key="qr-code" to={Util.SCAN}>
				{Util.loc("Lecteur QR code")}
			</Link>,
		];

		// We check if the user is connected
		if (!registered) {
			// User not connected
			contents.push(
				<Link key="sign-in" className="sign" to={Util.HOME_SIGNIN}>
					{Util.loc("Se connecter")}
				</Link>
			);
		} else {
			contents.unshift(
				<Link key="handling-invoices" to={Util.ACCOUNT_INVOICES}>
					{Util.loc("Mes factures")}
				</Link>
			);
			if (bellNb)
				contents.unshift(
					<Link key="handling-consum" to={Util.ACCOUNT_CONSUM}>
						{Util.loc("Ma consommation")}
					</Link>
				);
			if (bellNb)
				contents.unshift(
					<Link key="handling-contacts" to={Util.CONTACTS}>
						{Util.loc("Je gère mes contacts")}
					</Link>
				);
			contents.unshift(
				<Link key="my-profil" to={Util.ACCOUNT}>
					{Util.loc("Mon profil") +
						" (" +
						this.props.App.state.me.firstname +
						")"}
				</Link>
			);
			if (bellNb)
				contents.unshift(
					<Link key="dashboard" to={Util.BOARD}>
						{Util.loc("Tableau de bord")}
					</Link>
				);

			if (admin)
				contents.push(
					<a
						key="admin"
						href={Util.URL + "admin.php"}
						target="_blank"
						rel="noopener noreferrer"
					>
						{Util.loc("Backoffice")}
					</a>
				);

			contents.push(
				<span
					key="sign-out"
					onClick={this.onSignOutClick}
					className="sign"
				>
					{Util.loc("Se déconnecter")}
				</span>
			);
		}

		// Now we will check if the device is not a computer
		let deviceContents = [...this.renderNavigationLinks()];

		// We add a line separator
		deviceContents.unshift(<div key="separator" />);

		contents = [
			...contents,
			<section
				key="nav-link-container"
				className="nav-link-container d-block d-lg-none"
			>
				{deviceContents}
			</section>,
		];

		return contents;
	};

	renderNavigationLinks() {
		return [
			<a href={"https://spoors.fr/#produits"}>
				{Util.loc("Nos produits")}
			</a>,
			<a href={"https://spoors.fr/#Installation"}>
				{Util.loc("Comment ça marche")}
			</a>,
		];
	}

	setTopBar() {
		this.setState({ showTopBar: window.innerWidth >= 570 });
	}

	componentDidMount() {
		window.addEventListener("resize", this.setTopBar);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.setImgSize);
	}
	render() {
		const { App } = this.props;
		let registered = this.props.App.isRegistered();
		const className = this.props.className ?? "";

		return (
			<>
				{window.innerWidth >= 570 && (
					<section id="home-bar-top" className={className}>
						<Link key="qr-code" to={Util.SCAN}>
							<img src={QrCode} />
							{Util.loc("Lecteur QrCode")}
						</Link>
						{!registered ? (
							<Link key="sign-in" to={Util.HOME_SIGNIN}>
								<img src={User} />
								{Util.loc("Se connecter")}
							</Link>
						) : (
							<Link key="my-profil" to={Util.ACCOUNT}>
								<img src={User} />
								{Util.loc("Mon profil") +
									" (" +
									this.props.App.state.me.firstname +
									")"}
							</Link>
						)}
					</section>
				)}

				<section id="home-bar" className={className}>
					<a href={"https://spoors.fr"}>
						<img
							src={SpoorsLogo}
							alt="spoors-logo"
							className="brand-container"
						/>
					</a>

					<div className="links-container d-none d-lg-flex">
						{this.renderNavigationLinks()}
					</div>

					<div className="buttons-container">
						<SelectLanguage />
						<div className="menu-container">
							<button
								onClick={this.onBurgerBtnClick}
								className="menu-btn"
							>
								<img src={MenuBurger} alt="menu-burger-logo" />
							</button>
							{this.renderMenu()}
						</div>
					</div>
				</section>
			</>
		);
	}
}

HomeTopMenu.propTypes = {
	App: PropTypes.object.isRequired,
	className: PropTypes.string,
};

export default HomeTopMenu;
