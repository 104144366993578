import React from "react";

import Api from "./lib/Api.js";
import Close from "./style/img/close.png";

import CenteredContainer from "./Components/CenteredContainer";
import Cookies from "./Components/Cookies";
import Logo from "./style/img/logo.png";
class HomeCgv extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cgu: "",
			scrollToPolicySection: false,
			cancelImgPosition: "absolute",
			topPosition: "35px",
			done: false,
		};

		this.cookiesModal = undefined;

		new Api().fetch(
			"cguGet",
			{ doc: this.props.doc ? this.props.doc : "" },
			result => {
				if (!result) return this.props.App.alert();
				if (result.err)
					return this.props.App.alert(result.err, result.msg);
				this.setState({ cgu: result.cgu, done: true });
			}
		);
	}

	onCookiesClicked = () => {
		Cookies.instance.showModal();
	};

	onScroll = e => {
		const scrollY = e.currentTarget.scrollY;

		if (scrollY > 128) {
			this.setState({ cancelImgPosition: "fixed", topPosition: "0px" });
		} else {
			this.setState({
				cancelImgPosition: "absolute",
				topPosition: "35px",
			});
		}
	};

	static getDerivedStateFromProps(props, state) {
		const { state: locationState } = props.App.props.history.location;

		if (
			locationState &&
			locationState.displayCookiesPolicy &&
			state.scrollToPolicySection === false
		) {
			return {
				scrollToPolicySection: true,
			};
		}

		return null;
	}

	componentWillUnmount() {
		if (this.cookiesModal)
			this.cookiesModal.removeEventListener(
				"click",
				this.onCookiesClicked
			);

		window.removeEventListener("scroll", this.onScroll);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { cgu, scrollToPolicySection } = this.state;

		if (cgu && prevState.cgu === "" && scrollToPolicySection) {
			const cookiesH1 = document.querySelectorAll("h1")[8];

			cookiesH1.scrollIntoView();

			this.setState({ scrollToPolicySection: false });
		}

		if (cgu && prevState.cgu === "") {
			this.cookiesModal = document.querySelector("#CFC-cookies-modal");

			if (this.cookiesModal)
				this.cookiesModal.addEventListener(
					"click",
					this.onCookiesClicked
				);

			window.addEventListener("scroll", this.onScroll);
		}
	}

	render() {
		const { cancelImgPosition, topPosition } = this.state;

		return (
			<>
				<div
					id="home-cgv"
					style={{ position: cancelImgPosition, top: topPosition }}
				>
					<img
						onClick={() => this.props.App.goBack()}
						src={Close}
						alt="fermer"
					/>
				</div>
				<CenteredContainer id="home-cgv-content">
					{/*					<TopMenu App={this.props.App} noBurger={true} />*/}
					<div className="col-12 col-sm-10 col-md-8 col-lg-6 px-0 mx-auto CGU">
						<p className="c12 text-center">
							<img
								alt=""
								src={Logo}
								style={{
									width: "80px",
									height: "80px",
									marginTop: "140px",
								}}
							/>
						</p>
						<div
							className="cgu"
							dangerouslySetInnerHTML={{ __html: this.state.cgu }}
						/>
					</div>
				</CenteredContainer>
			</>
		);
	}
}

export default HomeCgv;
