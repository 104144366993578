import React, { useState } from "react";

import Util from "../lib/Util";
import Api from "../lib/Api.js";
import { Link } from "react-router-dom";

function ConsumSituation({ App, moreInformations }) {
	const [count, setCount] = useState(0);
	const [total, setTotal] = useState(0);
	const [start, setStart] = useState(true);
	const [hiden, setHiden] = useState(true);

	if (start) {
		setStart(false);
		new Api().fetch("consumSituation", {}, result => {
			//			console.log(result);
			if (!result) return App.alert();
			if (result.err) return App.alert(result.err, result.msg);
			setTotal(result.total);
			setCount(result.count);
			setHiden(false);
		});
	}

	return (
		!hiden && (
			<div id="consum-situation">
				<p className="title-appels-sms">
					{Util.loc(
						"Ma consommation (Appels anonymes et envois de SMS)"
					)}
				</p>
				<div
					className={
						count > 5 ? "consum-text-green" : "consum-text-orange"
					}
				>
					<span className="consum-values">
						{Util.fillStr(Util.loc("reste [count]"), {
							count: count,
						})}
						&nbsp;
					</span>
					<span className="consum-total">
						{Util.fillStr(Util.loc("/ [total] unités"), {
							total: total,
						})}
					</span>
				</div>
				<progress
					className={
						count > 5
							? "board-progressBar-green"
							: "board-progressBar-orange"
					}
					value={count}
					max={total}
				></progress>
				<div className="text-center mt-3">
					<span className="consum-text">
						{Util.loc(
							"Les unités permettent d’anonymiser les appels et SMS, un SMS ou une minute d’appel anonyme consommant une unité."
						)}
					</span>
				</div>
				{count <= 5 ? (
					<div className="text-center mt-3">
						<span className="text-solde-faible">
							{Util.loc(
								"Solde faible : vous devriez\u00a0recharger\u00a0!"
							)}
						</span>
					</div>
				) : null}
				<Link to={Util.PURCHASE_UNITS}>
					<button className="btn-solde-faible">
						+ {Util.loc("d'unités")}
					</button>
				</Link>
				{moreInformations ? (
					<div className="detail-conso">
						<Link to={Util.ACCOUNT_CONSUM}>
							<span className="detail-conso-text">
								{Util.loc("Détails conso")}
							</span>
						</Link>
					</div>
				) : null}
			</div>
		)
	);
}

export default ConsumSituation;
