import React from "react";
import Proptypes from "prop-types";

import Form from "./Form";
import Util from "../lib/Util";
import SelectCountry from "./SelectCountry";

class FormCoord extends React.Component {
	constructor(props) {
		super(props);
		let data = {
			firstname: "",
			lastname: "",
			phone: "",
			email: "",
		};

		this.story = this.props.App.storyGet(data);

		this.state = {
			firstname: this.story.firstname,
			lastname: this.story.lastname,
			countryCode: Util.countryFromPhone(this.story.phone),
			phone: this.story.phone,
			email: this.story.email,
		};
	}
	onBlur(field) {
		let value = this.state[field].trim();
		this.onChange(value, field, true);
	}

	onChange(value, field, process) {
		if (field === "phone") {
			value = Util.niceInternationalPhone(value, this.state.countryCode);
		} else value = Util.nice(value, field);
		let update = { [field]: value };
		this.setState(update);
		this.props.App.storyUpdate(update);
		if (this.props.form) this.props.form.notify(process);
	}

	phonePopup() {
		this.props.App.popup({
			icon: "ok",
			text: Util.loc(
				"A noter : dans mon espace privé, je pourrai ultérieurement rediriger les alertes vers une autre personne et paramétrer les horaires d’appel, si je le souhaite"
			),
			yes: {
				text: Util.loc("Ok"),
				value: true,
			},
		});
	}

	onCountry(value) {
		if (value === this.state.countryCode) return;
		this.setState({ countryCode: value });
		this.onChange("", "phone", true);
		if (this.refInput) this.refInput.focus();
	}

	render() {
		return (
			<Form label="">
				<div className="form-row">
					<div className="w-100">
						<div className="row form-group mb-3">
							<div className="col-12 px-0">
								<input
									type="text"
									spellCheck={false}
									className="form-control formControlStep"
									id="first-name"
									placeholder={""}
									required
									value={this.state.firstname}
									onKeyPress={event => {
										if (event.key === "Enter")
											event.target.blur();
									}}
									onChange={event => {
										this.onChange(
											event.target.value,
											"firstname"
										);
									}}
									onBlur={event => {
										this.onBlur("firstname");
									}}
								/>
								<label htmlFor="first-name">
									{Util.loc("Prénom *")}
								</label>
								<div
									style={Util.check(
										this.state.firstname,
										true
									)}
									className="invalid-tooltip"
								>
									{Util.loc(
										"Veuillez remplir ce champ correctement"
									)}
								</div>
							</div>
						</div>
						<div className="row form-group mb-3">
							<div className="col-12 px-0">
								<input
									type="text"
									spellCheck={false}
									className="form-control formControlStep"
									id="name"
									placeholder={""}
									required
									value={this.state.lastname}
									onKeyPress={event => {
										if (event.key === "Enter")
											event.target.blur();
									}}
									onChange={event => {
										this.onChange(
											event.target.value,
											"lastname"
										);
									}}
									onBlur={event => {
										this.onBlur("lastname");
									}}
								/>
								<label htmlFor="name">
									{Util.loc("Nom *")}
								</label>
								<div
									style={Util.check(
										this.state.lastname,
										true
									)}
									className="invalid-tooltip"
								>
									{Util.loc(
										"Veuillez remplir ce champ correctement"
									)}
								</div>
							</div>
						</div>
						{/* <div className="row mb-3 claimFormALert">
							<p className="etape">{Util.loc("M'alerter par :")}</p>
						</div> */}

						{/* <div className="mb-4">
							<p
								className="color-title"
								style={{ fontSize: "0.85rem" }}
							>
								{Util.loc(
									"Je renseigne mon numéro pour être contacté anonymement si mon QR code est scanné. Mon numéro ne sera pas dévoilé !"
								)}
							</p>
						</div> */}
						<div className="row form-group mb-3">
							<div className="col-12 px-0">
								<SelectCountry
									onCountry={country =>
										this.onCountry(country)
									}
									countryCode={this.state.countryCode}
								/>
								<input
									type="tel"
									spellCheck={false}
									className="form-control formControlStep"
									id={"phone"}
									ref={ref => (this.refInput = ref)}
									placeholder={""}
									required
									value={this.state.phone}
									onKeyPress={event => {
										if (event.key === "Enter")
											event.target.blur();
									}}
									onBlur={event => {
										this.onBlur("phone");
									}}
									onChange={event => {
										this.onChange(
											event.target.value,
											"phone",
											true
										);
									}}
								/>

								<label htmlFor={"phone"}>
									{Util.loc(
										"Mon numéro de téléphone (facultatif)"
									)}
								</label>
								<div
									style={Util.checkInternationalPhone(
										this.state.phone,
										this.state.countryCode,
										this.props.withNext
									)}
									className="invalid-tooltip"
								>
									{Util.loc(
										"Veuillez saisir un numéro valide"
									)}
								</div>
							</div>
						</div>
						<div className="row form-group">
							<div className="col-12 px-0">
								<input
									type="text"
									spellCheck={false}
									className="form-control formControlStep"
									id="email"
									placeholder={""}
									required
									value={this.state.email}
									onKeyPress={event => {
										if (event.key === "Enter")
											event.target.blur();
									}}
									onChange={event => {
										this.onChange(
											event.target.value,
											"email"
										);
									}}
									onBlur={event => {
										this.onBlur("email");
									}}
								/>
								<label htmlFor="email">
									{Util.loc("Mon Email *")}
								</label>
								<div
									style={Util.checkEmail(
										this.state.email,
										true
									)}
									className="invalid-tooltip"
								>
									{Util.loc(
										"Veuillez remplir ce champ correctement"
									)}
								</div>
							</div>
						</div>
						<div className="row mb-3 claimFormALert">
							<p className="infoAlert">
								{Util.loc(
									"Dans mon espace privé, je pourrai ultérieurement rediriger les alertes vers une autre personne et paramétrer les horaires d'appel, si je le souhaite."
								)}
							</p>
						</div>
					</div>
				</div>
			</Form>
		);
	}
}

export default FormCoord;
