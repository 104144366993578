import React from "react";
import Proptypes from "prop-types";

import Util from "../lib/Util";

const UtilityItem = ({ onChecked, id, img, label, check }) => (
	<div id={id} className={`utility-item ${check ? "checked" : ""}`}>
		<div onClick={onChecked} className={`icon-step-one`}>
			<img src={img} alt="ico illustration" />
			<p className="text-step-one">{label}</p>
		</div>
	</div>
);

UtilityItem.propTypes = {
	onChecked: Proptypes.func.isRequired,
	id: Proptypes.string.isRequired,
	img: Proptypes.string.isRequired,
	label: Proptypes.string.isRequired,
	check: Proptypes.bool.isRequired,
};

export default UtilityItem;
