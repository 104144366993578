import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import Api from "./lib/Api.js";

import CenteredContainer from "./Components/CenteredContainer";
import TopMenu from "./Components/TopMenu";
import ConsumSituation from "./Components/ConsumSituation";
import BottomPage from "./Components/BottomPage";

class Board extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bells: [],
		};
		new Api().fetch("bellDomain", {}, result => {
			//			console.log(result);
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.setState({ bells: result.bells });
		});
	}
	select(elem) {
		this.props.App.go(Util.BELL_EDIT + "/" + elem.code);
	}

	render() {
		const { App } = this.props;
		let bells = this.state.bells;
		//		bells = bells.concat(bells).concat(bells);
		return (
			<CenteredContainer id="page" className="screen-container">
				<div id="board" className="claimBackground">
					<TopMenu App={App} />
					<div className="row d-flex flex-row justify-content-center inputTop">
						<div className="col-12 col-md-10 col-lg-6 ">
							<div className="d-flex align-items-center justify-content-between">
								<label className="form-control">
									{Util.loc("MON TABLEAU DE BORD")}
								</label>
							</div>
						</div>
					</div>

					<div id="board" className="row pb-block">
						<div className="col-12 col-sm-10 col-md-8 col-lg-6 text-center mx-auto">
							<ConsumSituation moreInformations />
							<p className="subtitle-spoors">
								{Util.loc("Mes SPOORS")}
							</p>
							{bells
								? bells.map((elem, index) => {
										return (
											<div
												className="btn-spoors"
												key={index}
											>
												<div className="col-12">
													<div className="custom-control checkbox-block">
														<input
															type="checkbox"
															id={elem.id}
															className="custom-control-input"
															onChange={() =>
																this.select(
																	elem
																)
															}
														/>
														<label
															className="custom-control-label"
															htmlFor={elem.id}
															style={{
																cursor:
																	"pointer",
															}}
														>
															{Util.bellTitle(
																elem
															)}
														</label>
													</div>
												</div>
											</div>
										);
								  })
								: null}
						</div>
					</div>
				</div>
				<BottomPage />
			</CenteredContainer>
		);
	}
}

Board.propTypes = {
	App: Proptypes.object.isRequired,
};

export default Board;
