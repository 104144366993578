import React from "react";
import Proptypes from "prop-types";

const Form = ({ label, children }) => (
	<div className="form-container">
		<h3>{label}</h3>
		{children}
	</div>
);

/*const Form = ({ label, children }) => (
	<div className="row d-flex flex-row justify-content-center">
		<div className="form-container">
			<h3>{label}</h3>
			{children}
		</div>
	</div>
);
*/
Form.propTypes = {
	label: Proptypes.string,
};

export default Form;
