import React from "react";

import Util from "./lib/Util";
import Api from "./lib/Api.js";

import Page from "./Components/Page";

class PurchaseStart extends React.Component {
	constructor(props) {
		super(props);

		const provider = Util.getProviderExt();
		new Api().fetch(
			"purchaseStart",
			{
				provider: provider.name,
				operation: provider.operation,
			},
			result => {
				if (!result) return this.props.App.alert();
				if (result.err)
					return this.props.App.alert(result.err, result.msg);
				this.props.App.storySet(result.story);
				this.props.App.partnersSet(result.partners);
				if (result.prices) this.props.App.pricesSet(result.prices);
				this.props.App.go(Util.PURCHASE_FORM);
			}
		);
	}
	render() {
		const { App } = this.props;
		return (
			<Page App={App} title="" headerTheme="light">
				<div className="containt" />
			</Page>
		);
	}
}

export default PurchaseStart;
