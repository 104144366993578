import React, { Component } from "react";
import Util from "./lib/Util";
import IcoMinus from "./style/img/ei-moins.png";
import { COUNTRIES } from "./lib/Countries";

class Test extends Component {
	constructor(props) {
		super(props);
		this.state = {
			countryCode: "",
			phoneNumber: "",
			isSaved: false,
			isInputDisabled: true,
		};
	}

	onBlur(value) {
		console.log("onBlur", value, "tel");
		if (!this.check(value)) {
			console.log("restor");
			return;
		}
		this.setState({ isSaved: true });
	}

	onChange(value) {
		this.setState({ phoneNumber: value });
	}

	remove() {
		this.props.App.confirmPopup(
			Util.loc("Etes-vous sûr de vouloir supprimer ce numéro ?"),
			result => {
				if (!result) return;
				this.setState({ phoneNumber: "" });
			}
		);
	}

	check(value) {
		const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
		let numberProto;

		if (typeof value === undefined || value === "")
			return { display: "block" };

		try {
			numberProto = phoneUtil.parse(value, this.state.countryCode);
		} catch (e) {
			throw console.log("NumberParseExeption was thrown: ", e);
		}
		console.log("is valid number: ", phoneUtil.isValidNumber(numberProto));
		return phoneUtil.isValidNumber(numberProto);
	}

	onCountry(event) {
		for (let country in COUNTRIES()) {
			if (event.target.value === country) {
				let countryCode = event.target.value;
				this.setState({
					countryCode: countryCode,
					isInputDisabled: false,
				});
				return;
			} else {
				this.setState({ isInputDisabled: true });
			}
		}
	}

	getFormat() {
		const PNF = require("google-libphonenumber").PhoneNumberFormat;
		const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
		let numberProto;

		if (this.state.isSaved && this.state.phoneNumber.length > 2) {
			try {
				//numberProto = phoneUtil.parse("0770476282", "GF");
				numberProto = phoneUtil.parse(
					this.state.phoneNumber,
					this.state.countryCode
				);
			} catch (e) {
				throw console.log("NumberParseExeption was thrown: ", e);
			}

			try {
				console.log(
					"valide :",
					phoneUtil.isValidNumberForRegion(numberProto, "GF")
				);
				//if (phoneUtil.isValidNumber(numberProto)) {
				let CC = phoneUtil.getRegionCodeForNumber(numberProto);
				console.log("code :", this.state.countryCode);
				return phoneUtil.getNumberType(numberProto); //phoneUtil.format(numberProto, PNF.INTERNATIONAL);

				//}
			} catch (e) {
				throw console.log("ValidNumberExeption was thrown: ", e);
			}
		}
	}

	render() {
		let phone = "023722106";
		let country = "BE";
		const PNF = require("google-libphonenumber").PhoneNumberFormat;
		const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

		let numberProto = phoneUtil.parse(phone, country);
		let numInterna = phoneUtil.format(numberProto, PNF.INTERNATIONAL);
		let valid = phoneUtil.isValidNumberForRegion(numberProto, "BE");
		console.log("valid", phone, valid);
		console.log(numberProto);
		console.log(numInterna);

		return (
			<div className="row form-category-title">
				<div className="col-12 px-5">
					<select
						id="countries"
						onCountry={country => this.onCountry(country)}
						placeholder="--Choose an option--"
					>
						{Object.entries(COUNTRIES()).map((country, i) => (
							<option key={i} value={country[0]}>
								{country[1] + " (" + country[0] + ")"}
							</option>
						))}
					</select>

					<div>
						<br />
						{this.state.countryCode}
					</div>

					<input
						type="tel"
						autoComplete={Util.random()}
						spellCheck={false}
						className="form-profil-information"
						//required
						disabled={this.state.isInputDisabled}
						value={this.state.phoneNumber}
						onKeyPress={event => {
							if (event.key === "Enter") event.target.blur();
						}}
						onBlur={() => {
							this.onBlur(this.state.phoneNumber);
						}}
						onChange={event => {
							this.onChange(event.target.value);
						}}
					/>
					<label>{Util.loc("Téléphone *")}</label>
					<div
						//style={this.state.phoneNumber === ""}
						className="invalid-tooltip"
					>
						{Util.loc("Veuillez saisir un numéro")}
					</div>
				</div>
				<div className="trash">
					<button
						style={{
							background: "none",
							border: "none",
							boxShadow: "none",
							paddingBottom: "20px",
						}}
						onClick={() => this.remove()}
					>
						<img src={IcoMinus} alt="corbeille" />
					</button>
				</div>

				<div className="px-5">
					<br />
					{this.getFormat()}
				</div>
			</div>
		);
	}
}

export default Test;
