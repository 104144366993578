import React from "react";
import { Link } from "react-router-dom";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import GoogleAnalytics from "./lib/GoogleAnalytics";

import Page from "./Components/Page";
import Phone from "./Components/Phone";

// img
import Tel from "./style/img/phone-white.png";

class VisitAuto extends React.Component {
	constructor(props) {
		super(props);
		this.props.App.storyNeed("/VISIT_AUTO", Util.HOME);
		this.state = this.props.App.storyGet({});
		this.story = this.props.App.storyGet();
		this.ga = GoogleAnalytics.getInstance();
	}

	goVisit() {
		this.props.App.storyUpdate({ url: "/VISIT" });
		this.props.App.go(Util.VISIT);
	}
	goRedirect() {
		this.props.App.storyUpdate({ url: "/VISIT" });
		this.props.App.go(Util.BELL_ALERT);
	}
	goView(code) {
		this.props.App.go(Util.BELL_EDIT + "/" + code);
	}
	render() {
		const { App } = this.props;
		let admin = this.props.App.isAdmin();
		let story = this.props.App.storyGet();
		let welcome = Util.fillStr(Util.loc("Bonjour [firstname],"), {
			firstname: story.firstname,
		});
		let showBoardLink = this.story.bellRole === Util.ROLE_OWNER;

		//		console.log("render", showRing);
		return (
			<>
				<Page App={App} withoutHeader>
					<div className="row containt text-center claimBackground">
						<div className="m-auto col-12 col-sm-10 col-md-8 col-lg-6">
							<div className="row">
								<div className="m-auto col-12 pt-block mb-4">
									<div className="px-3 py-3">
										<p className="text-center color-title font-size-1-3 font-weight-regular mb-4 mt-4">
											{welcome}
										</p>
										<p className="text-center color-title font-size-1-3 font-weight-regular mb-4 mt-4">
											{Util.loc(
												"que voulez-vous faire avec votre SPOORS ?"
											)}
										</p>
									</div>
								</div>
							</div>
							{showBoardLink ? (
								<div className="row">
									<div className="col-10 m-auto d-flex justify-content-center">
										<button
											className="btn btn-board btn-block"
											onClick={() =>
												this.goView(story.code)
											}
										>
											{Util.loc(
												"Personnaliser mon SPOORS"
											)}
										</button>
									</div>
								</div>
							) : null}
							<div className="row">
								<div className="col-10 m-auto d-flex justify-content-center">
									<button
										className="btn btn-board btn-block"
										onClick={() => this.goRedirect()}
									>
										{Util.loc("Rediriger les alertes")}
									</button>
								</div>
							</div>
							<div className="row">
								<div className="col-10 m-auto d-flex justify-content-center">
									<button
										className="btn btn-board btn-block"
										onClick={() => this.goVisit()}
									>
										{Util.loc("Utiliser comme un visiteur")}
									</button>
								</div>
							</div>
							{admin ? (
								<a
									href={
										Util.URL +
										"admin.php#/bell/" +
										encodeURIComponent(
											JSON.stringify({
												code: this.story.code,
											})
										)
									}
									target="_blank"
									rel="noopener noreferrer"
								>
									π
								</a>
							) : null}
						</div>
					</div>
				</Page>
			</>
		);
	}
}

VisitAuto.propTypes = {
	App: Proptypes.object.isRequired,
};

export default VisitAuto;
