import React, { Component } from "react";
import { Link } from "react-router-dom";

import Util from "../lib/Util";
import GoogleAnalytics from "../lib/GoogleAnalytics";

import CookiesModal from "./CookiesModal";

class Cookies extends Component {
	static instance;

	constructor(props) {
		super(props);

		this.state = {
			contentToDisplay: null,
		};

		Cookies.instance = this;

		this.googleAnalytics = GoogleAnalytics.getInstance();
	}

	static addCookie(cookieName, cookieValue) {
		document.cookie = `${cookieName}=${cookieValue}; path=/`;
	}

	static removeCookie(cookieName) {
		let d = new Date();
		d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * -1);

		document.cookie = `${cookieName}=;path=/;expires=${d.toGMTString()}`;
	}

	static getCookie(cookieName) {
		const cookiesList = document.cookie.split(";");

		for (let cookie of cookiesList) {
			if (cookie.includes(cookieName)) {
				return cookie.split("=")[1];
			}
		}

		return null;
	}

	onButtonClick = () => {
		window.localStorage.setItem("cookies-approval", "validated");

		/*// TODO ALL COOKIES SHOULD BE ADD HERE AFTER TRIAL PERIOD
		window.localStorage.setItem("google-analytics", "enabled");

		//We start GoogleAnalytics tracking
		this.googleAnalytics.startWatcher();*/

		this.setState({ contentToDisplay: null });
	};

	showModal() {
		this.setState({ contentToDisplay: "modal" });
	}

	renderContent() {
		const { contentToDisplay } = this.state;

		if (contentToDisplay === "modal") {
			return (
				<CookiesModal
					onClose={() => this.setState({ contentToDisplay: null })}
				/>
			);
		}

		if (contentToDisplay === "approval") {
			return (
				<div id="cookies">
					<div className="text-content">
						{Util.loc(
							"En poursuivant votre navigation, vous acceptez nos cookies destinés à l'affichage d'annonces liées à vos intérêts et à nos statistiques. Pour plus d'informations, vous pouvez consulter"
						)}
						<Link
							to={{
								pathname: "/HOME_CGV",
								state: { displayCookiesPolicy: true },
							}}
						>
							{Util.loc("notre politique de cookies")}
						</Link>
						.
					</div>
					<button onClick={this.onButtonClick}>
						{Util.loc("J'ai compris")}
					</button>
				</div>
			);
		}

		return null;
	}

	componentDidMount() {
		const cookieApproval = window.localStorage.getItem("cookies-approval");

		//THE FOLLOWING LINES OF CODE ARE HERE ONLY DURING THE GOOGLE ANALYTICS TRIAL PERIOD
		/*
			After trial period, GoogleAnalytics cookie should be enabled only if the users clicks on the button
		 */
		if (!this.googleAnalytics.enabled) {
			window.localStorage.setItem("google-analytics", "enabled");
			//We start GoogleAnalytics tracking
			this.googleAnalytics.startWatcher();
		}
		//

		if (!cookieApproval || cookieApproval !== "validated") {
			this.setState({ contentToDisplay: "approval" });
			return;
		}
	}

	render() {
		return this.renderContent();
	}
}

export default Cookies;
