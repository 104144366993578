import React from "react";
import { Link } from "react-router-dom";

import Util from "../lib/Util";

import FooterCard from "./FooterCard";

class Footer extends React.Component {
	renderInside() {
		return (
			<section id="footer" className="w-100">
				<div className="spacer" />
				<div className="row footer-items d-flex justify-content-between textual-information px-4">
					<div className="col-sm-12 col-md-4">
						<FooterCard title={Util.loc("Qu'est-ce que SPOORS ?")}>
							<div
								className="spoors-content body-1"
								dangerouslySetInnerHTML={{
									__html:
										"<p>" +
										Util.loc(
											"SPOORS s’est donné comme mission de vous rendre accessible de n’importe où et de manière anonyme, grâce à une technologie brevetée."
										) +
										"<br/><br/><strong>" +
										Util.loc(
											"SPOORS est une entreprise intégrée au startup studio corporate Wefound."
										) +
										"</strong></p>",
								}}
							/>
						</FooterCard>
					</div>
					<div className="col-sm-12 col-md-4 col-lg-3">
						<FooterCard title={Util.loc("Navigation")}>
							<ul className="d-flex flex-column align-items-start">
								<li>
									<a href={"https://spoors.fr/#produits"}>
										{Util.loc("Nos produits")}
									</a>
								</li>
								<li>
									<Link to={Util.HOME_CGV}>
										{Util.loc(
											"Conditions générales de vente"
										)}
									</Link>
								</li>
								<li>
									<Link to={Util.HOME_CONTACT}>
										{Util.loc("Contactez-nous")}
									</Link>
								</li>
							</ul>
						</FooterCard>
					</div>
					<div className="col-sm-12 col-md-2 logo-container " />
				</div>
				<div className="row">
					<div className="col-12 copy-right px-4">
						&#169; SPOORS 2020
					</div>
				</div>
			</section>
		);
	}
	render() {
		return this.renderInside();
	}
}

export default Footer;
