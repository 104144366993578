import React from "react";
import PropTypes from "prop-types";

const FooterCard = props => (
	<div
		className={`footer-card d-flex flex-column align-items-start ${props.className}`}
	>
		<div className="row">
			<div className="col-12">
				<h3>{props.title}</h3>
			</div>
		</div>
		<div className="row">
			<div className="col-12">{props.children}</div>
		</div>
	</div>
);

FooterCard.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default FooterCard;
