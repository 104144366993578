import React, { Component } from "react";
import PropTypes from "prop-types";

class TimePicker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hour: props.initialHour ? props.initialHour : 0,
			minutes: props.initialMinutes ? props.initialMinutes : 0,
		};
	}

	onSelectorChange = (selector, ev) => {
		const value = ev.target.value;

		switch (selector) {
			case "hour":
				this.setState({ hour: value });
				break;
			case "minutes":
				this.setState({ minutes: value });
				break;
			default:
				break;
		}
	};

	renderPrefix() {
		const { prefix } = this.props;

		if (prefix) {
			return <span className="prefix">{prefix}</span>;
		}

		return null;
	}

	renderValueContent(val) {
		if (val < 10) {
			return `0${val}`;
		}

		return val;
	}

	renderHourSelector = () => {
		const { hour } = this.state;
		let selectorContent = [];

		for (let i = 0; i <= 23; i++) {
			selectorContent.push(
				<option key={i} value={i}>
					{this.renderValueContent(i)}
				</option>
			);
		}

		return (
			<select
				onChange={ev => this.onSelectorChange("hour", ev)}
				value={hour}
				className="selector"
			>
				{selectorContent}
			</select>
		);
	};

	renderMinutesSelector = () => {
		const { minutes } = this.state;
		let selectorContent = [];

		for (let i = 0; i <= 55; i += 5) {
			selectorContent.push(
				<option key={i} value={i}>
					{this.renderValueContent(i)}
				</option>
			);
		}

		return (
			<select
				onChange={ev => this.onSelectorChange("minutes", ev)}
				value={minutes}
				className="selector"
			>
				{selectorContent}
			</select>
		);
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { hour, minutes } = this.state;

		if (prevState.hour !== hour || prevState.minutes !== minutes) {
			this.props.onTimeChange(hour, minutes);
		}
	}

	render() {
		return (
			<div className="time-picker">
				{this.renderPrefix()}
				{this.renderHourSelector()}
				<span className="separator">:</span>
				{this.renderMinutesSelector()}
			</div>
		);
	}
}

TimePicker.propTypes = {
	onTimeChange: PropTypes.func.isRequired,
	initialHour: PropTypes.number,
	initialMinutes: PropTypes.number,
	prefix: PropTypes.string,
};

export default TimePicker;
