import React from "react";
import Proptypes from "prop-types";

import Util from "./lib/Util";
import Api from "./lib/Api.js";

import CenteredContainer from "./Components/CenteredContainer";
import TopMenu from "./Components/TopMenu";

import ArrowUp from "./style/img/arrow-up-blue.svg";
import ArrowDown from "./style/img/arrow-down-blue.svg";
import { ReactComponent as Download } from "./style/img/download.svg";
import BottomPage from "./Components/BottomPage";

class AccountInvoices extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			invoices: [],
			yearDisplayed: 0,
		};
		new Api().fetch("invoiceList", {}, result => {
			//			console.log(result);
			if (!result) return this.props.App.alert();
			if (result.err) return this.props.App.alert(result.err, result.msg);
			this.setState({ invoices: result.invoices });
		});
	}
	select(elem) {
		console.log("select " + elem.id);
		window.open(elem.ext, "_blank");
	}

	render() {
		const { App } = this.props;
		let invoices = this.state.invoices;
		let years = invoices.map(elem => Util.getYear(elem.time));
		years = [...new Set(years)];
		//		bells = bells.concat(bells).concat(bells);
		return (
			<CenteredContainer id="page" className="screen-container">
				<div id="account-invoices" className="claimBackground">
					<TopMenu App={App} />
					<div className="row d-flex flex-row justify-content-center inputTop">
						<div className="col-12 col-md-10 col-lg-6 ">
							<div className="d-flex align-items-center justify-content-between padd20">
								<label className="form-control formControlStep ">
									{Util.loc("MES FACTURES")}
								</label>
							</div>
						</div>
					</div>

					<div id="account-invoices" className="row pt-5 pb-block">
						<div className="col-12 col-sm-10 col-md-8 col-lg-6 text-center mx-auto">
							{years.length ? (
								years.map((year, yearIndex) => {
									return (
										<div
											className="text-left"
											key={yearIndex}
										>
											<div
												className="facture-year-row"
												onClick={() =>
													this.state.yearDisplayed ===
													yearIndex
														? this.setState({
																yearDisplayed: -1,
														  })
														: this.setState({
																yearDisplayed: yearIndex,
														  })
												}
											>
												<label className="facture-year">
													{year}
												</label>
												<div>
													<img
														src={
															this.state
																.yearDisplayed ===
															yearIndex
																? ArrowDown
																: ArrowUp
														}
														className="arrow-img"
													/>
												</div>
											</div>
											{invoices
												.filter(
													e =>
														Util.getYear(e.time) ===
														year
												)
												.map((elem, invoiceIndex) => {
													return (
														this.state
															.yearDisplayed ===
															yearIndex && (
															<div
																className="facture-year-block"
																key={
																	invoiceIndex
																}
															>
																<label className="facture-date">
																	{Util.dateFormat(
																		elem.time
																	)}
																</label>
																<button
																	className="btn-ddl-facture"
																	onClick={() =>
																		this.select(
																			elem
																		)
																	}
																>
																	<span>
																		<Download />
																	</span>
																</button>
																<div className="facture-num-somme">
																	<label className="label-num-facture">
																		{
																			elem.number
																		}
																		<br />
																		<label className="label-paye">
																			{Util.loc(
																				"payé"
																			)}
																		</label>
																	</label>

																	<label className="label-somme-facture">
																		{elem.amount +
																			"€"}
																	</label>
																</div>
															</div>
														)
													);
												})}
										</div>
									);
								})
							) : (
								<div className="no-invoce">
									{Util.loc("Vous n'avez pas de facture")}
								</div>
							)}
						</div>
					</div>
				</div>
				<BottomPage />
			</CenteredContainer>
		);
	}
}

AccountInvoices.propTypes = {
	App: Proptypes.object.isRequired,
};

export default AccountInvoices;
